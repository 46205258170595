import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { usePromiseTracker } from "react-promise-tracker";
// import Spinner from "./lib/Spinner";

const loader = document.querySelector(".loader");

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--hide");
const hideLoader = () => loader.classList.add("loader--hide");

// const LoadingIndicator = (props) => {
//   const { promiseInProgress } = usePromiseTracker();
//   return promiseInProgress && <Spinner isFetching="Loading in progress" />;
// };
// the setTimeout simulates the time it takes react to load, and is not part of the solution
setTimeout(
  () =>
    // the show/hide functions are passed as props
    ReactDOM.render(
      <React.StrictMode>
        <App hideLoader={hideLoader} showLoader={showLoader} />
        {/* <Spinner /> */}
      </React.StrictMode>,
      document.getElementById("root")
    ),
  2000
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import 'bootstrap/dist/css/bootstrap.css';
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';
// import registerServiceWorker from './registerServiceWorker';

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const rootElement = document.getElementById('root');

// ReactDOM.render(
//   <BrowserRouter basename={baseUrl}>
//     <App />
//   </BrowserRouter>,
//   rootElement);

// registerServiceWorker();
