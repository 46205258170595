import {
  CloudDownloadOutlined,
  CloudFilled,
  CloudServerOutlined,
  CloudSyncOutlined,
  CloudTwoTone,
  InboxOutlined,
  PlusCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Row, Space, Upload } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import _ from "lodash";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import DocService from "Services/API/DocService";
import { notify } from "Services/ToastService";
import "./DocsUploader.css";

const DocsUploader = ({ principal, docType, handleUploadSuccess }) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showList, setShowList] = useState(true);
  useEffect(() => {
    setFileList([]);
  }, [principal]);

  useEffect(() => {
    setShowList(true);
  }, [fileList]);

  const onRemove = (file) => {
    let newFileList = fileList.slice();
    //const f = _.find(newFileList, { uid: file.uid });
    newFileList = newFileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
  };

  const beforeUpload = (file) => {
    let newFileList = [...fileList, file];
    setFileList(newFileList);
    return false;
  };

  const onChange = (uploadedData) => {
    console.log(uploadedData);
    let newFileList = uploadedData.fileList;
    //newFileList.push(file);
    setFileList(newFileList);
    //setFileList([...files]);
  };

  const doClear = () => {
    //let fl = fileList.slice();
    // fl.forEach((f) => {
    //   onRemove(f);
    // });
    setFileList([]);
  };

  const doUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file.originFileObj);
      //formData.append("files[]", file);
    });

    setUploading(true);

    if (!principal || principal === 0) {
      notify("No principal selected");
      return;
    }

    if (!docType || docType === "") {
      notify("No document type selected");
      return;
    }
    // You can use any AJAX library you like
    const { data, status, message } = await trackPromise(DocService.Doc_BulkUpload(formData, docType, principal));

    !status && notify(status, message);

    if (status) {
      doClear();
      handleUploadSuccess(true, "Documents uploaded successfully");
    }

    setUploading(false);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="box-dashed box-pad mr-auto ml-auto">
            <Dragger
              fileList={fileList}
              accept="image/*, text/*, application/pdf, application/vnd.ms-word, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
              multiple={true}
              listType="picture-card"
              showUploadList={true}
              onRemove={onRemove}
              beforeUpload={beforeUpload}
              onChange={onChange}
            >
              <PlusCircleFilled style={{ fontSize: "24px", color: "#feab36" }} />
              <br />
              <h3 className="text-large">New {docType} Documents</h3>
              <h2 className="text-disabled">Click or drag one or more files to this area to upload</h2>
              <p className="">
                <span>
                  Valid {docType.toLowerCase()} document filename format:{" "}
                  <b className="text-primary text-bold text-larger">
                    {docType === "External" && <>VESSEL - INSURANCE TYPE - DOCUMENT TYPE - INCEPTION DATE</>}
                    {docType === "Mortgagee" && <>VESSEL - DOCUMENT TYPE - EFFECTIVE DATE</>}
                    {docType === "Dialogue" && <>Any filename</>}
                  </b>{" "}
                  {(docType === "External" || docType === "Mortgagee") && <>(DD MM YYYY)</>}
                </span>
              </p>
            </Dragger>
          </div>
          {/* <Upload 
          fileList={fileList}
          accept="image/*, text/*, application/pdf, application/vnd.ms-word, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
          multiple={true}
          listType="picture-card"
          showUploadList={true}
          onRemove={onRemove}
          beforeUpload={beforeUpload}
          onChange={onChange}
        >
          <div style={{ cursor: "pointer" }} className="box box-pad box-success">
            <PlusCircleFilled style={{ fontSize: "24px", color: "#feab36" }} />
            <br />
            <h4 className="">New Documents</h4> 
          </div>
        </Upload> */}
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Space>
            <Button
              style={{ marginTop: "16px" }}
              className="bg-success btn-big"
              type="primary"
              onClick={doUpload}
              disabled={fileList.length === 0}
              loading={uploading}
            >
              <CloudSyncOutlined style={{ fontSize: "20px" }} />
              {uploading ? "Uploading" : "Start Upload"}
            </Button>
            <Button
              style={{ marginTop: "16px" }}
              className="btn-big"
              type="dashed"
              onClick={doClear}
              disabled={fileList.length === 0}
            >
              {" "}
              Clear
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default DocsUploader;
