import { snakeCaseToTitle } from "Lib/JsHelper";

export const InfoList = ({ obj }) => {
  if (!obj) return;
  // console.log(obj);
  let cols = Object.entries(obj);
  //console.log("cols", cols);
  return cols.map(([key, value]) => {
    return (
      <div className="info-gruop">
        <div className="info-label">{snakeCaseToTitle(key)}</div>
        <div className="info">{value}</div>
      </div>
    );
  });
};
