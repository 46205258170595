import BaseApiService from "Services/BaseApiService";

class EdocService extends BaseApiService {
  //#region Claims
  static async Doc_List(filters) {
    const url = `/docs/get`;
    const postData = filters;
    return await super.POST(url, postData);
  }

  static async Doc_Delete(ids) {
    const url = `/docs/delete`;
    const postData = ids; //string[]
    return await super.POST(url, postData);
  }

  static async Doc_Download(docId) {
    const url = `/docs/download/${docId}`;
    return await super.DOWNLOAD(url);
  }

  static async Doc_Save(doc) {
    const url = `/docs/save`;
    const postData = doc;
    return await super.POST(url, postData);
  }
  static async Doc_Upload(doc) {
    const url = `/docs/upload`;
    const postData = doc;
    return await super.POST(url, postData);
  }

  static async Doc_BulkUpload(docFiles, docType, principal) {
    const url = `/docs/bulkUpload/${docType}/${principal}`;
    const postData = docFiles;
    return await super.UPLOAD(url, postData);
  }
  //#endregion
}

export default EdocService;
