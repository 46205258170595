import { message } from "antd";

//https://sweetalert2.github.io/
export function notify(text, status, cssClass) {
  switch (status) {
    case true:
      message.success({
        content: text || "(Success)",
        className: cssClass || "text-bold",
        style: {
          marginTop: "9vh",
        },
        duration: 3,
      });
      break;
    case false:
      message.error({
        content: text || "(Error :: failed to fulfill the request on server)",
        className: cssClass || "text-bold",
        style: {
          marginTop: "9vh",
          // marginBottom: "30vh",
        },
        duration: 5,
      });
      break;
    default:
      message.info({
        content: text || "(Info)",
        className: cssClass || "text-bold",
        style: {
          marginTop: "9vh",
        },
        duration: 4,
      });
      break;
  }
}

export function alertify(text, status, timer, config) {
  const Swal = require("sweetalert2");
  const swal_config = {
    confirmButtonColor: "#1c78bc",
    ...config,
  };

  if (status === true) Swal.fire({ icon: "success", title: "Success", text: text, timer, ...swal_config });
  else if (status === false) Swal.fire({ icon: "error", title: "Failure", text: text, timer, ...swal_config });
  else if (status === "warning") Swal.fire({ icon: "warning", title: "Warning", text: text, timer, ...swal_config });
  else Swal.fire({ text: text, showCloseButton: true, timer });
}
