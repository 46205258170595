import React, { useState, useEffect } from "react";
import { Layout, Breadcrumb, Row, Col, Drawer } from "antd";

import NavHeader from "./NavHeader";
import Text from "antd/lib/typography/Text";
import SideMenu from "./SideMenu";
import { ProtectedRoute } from "Lib/ReactHelper";
import { Link, Redirect, Route } from "react-router-dom";
import _ from "lodash";
import environment from "Environment";
import { LoadingPanel } from "./LoadingPanels";
const { Header, Content, Footer, Sider } = Layout;

const ProtectedLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [layout, setLayout] = useState("full");
  const [userRole, setUserRole] = useState(props.userRole);
  const [modules, setModules] = useState(props.navigation);
  const [curModule, setCurModule] = useState(props.navigation && props.navigation[0]);
  const [curPage, setCurPage] = useState();
  const [curSubPage, setCurSubPage] = useState();
  const [pageTitle, setPageTitle] = useState("Dashboard");
  const [showSideBar, setShowSideBar] = useState(false);
  const [organization, setOrganization] = useState("Service Vault");
  //console.log("Default Layout", props.layout);
  useEffect(() => {
    setLayout(props.layout);
    console.log("layout", layout);
    if (props.layout !== "full") {
      setCollapsed(true);
    }
    handlePathChanged(window.location.pathname);
  }, [props.layout]);

  useEffect(() => {
    handlePathChanged(window.location.pathname);
  }, [window.location.pathname]);

  const handlePathChanged = (path) => {
    console.log("Path Changed", path);
    setCurModule(null);
    setCurPage(null);
    setCurSubPage(null);

    let p = path.split("/");
    let module, page, subPage;

    //1st level
    if (p && p.length >= 3 && p[2] !== "") {
      let modulePath = p[2] || "dashboard";
      module = props.navigation.filter((n) => n.path === "/app/" + modulePath)[0];
      setCurModule(module);
    }

    //2nd level
    if (p && p.length >= 4 && p[3] !== "") {
      if (module && module.items) {
        page = _.find(module.items, { path: path });
        if (page) {
          setCurPage(page);
        } else {
          //3rd level
          let subMenu = _.find(module.items, { items: [{ path: path }] });
          subPage = subMenu && _.find(subMenu.items, { path: path });
          if (subMenu) {
            setCurPage(subMenu);
          }
          if (subPage) {
            setCurSubPage(subPage);
          }
        }
      }
    }

    props.history.push(path);
  };

  const onOrgChanged = (organization) => {
    console.log("org selected", organization);
    setOrganization(organization);
  };

  const SideBarMenu = () => {
    return (
      <SideMenu
        topNav={curModule}
        history={props.history}
        collapsed={collapsed}
        onMenuChanged={handlePathChanged}
        onOrgChanged={onOrgChanged}
      />
    );
  };

  return (
    <>
      {/* <NavBar menu={SideBarMenu} /> */}
      <Layout>
        {
          <Drawer
            title="Edge ECP Menu"
            placement="left"
            // onClick={() => setShowSideBar(false)}
            onClose={() => setShowSideBar(false)}
            visible={showSideBar}
          >
            <SideMenu
              topNav={modules}
              history={props.history}
              collapsed={false}
              onMenuChanged={(e) => {
                handlePathChanged(e);
                setShowSideBar(false);
              }}
            />
          </Drawer>
          /*
        <Sider
          menu={null}
          className="sidebar"
          breakpoint={"md"}
          theme="light"
          collapsedWidth={0}
          trigger={null}
          width={240}
          style={{
            height: "100vh",
            position: "fixed",
            left: 0,
            borderRight: "dotted 1px #aaa",
            marginTop: "64px",
            paddingTop: "10px",
          }}
        >
          <SideMenu
            topNav={curModule}
            history={props.history}
            collapsed={collapsed}
            onMenuChanged={handlePathChanged}
            onOrgChanged={onOrgChanged}
          />
        </Sider> */
        }

        <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <NavHeader
            menu={SideBarMenu}
            layout={layout}
            history={props.history}
            modules={modules}
            onTopMenuChanged={handlePathChanged}
            onMenuButtonClicked={setShowSideBar}
            onSideBarMenuChanged={handlePathChanged}
            onOrgChanged={onOrgChanged}
            curModule={curModule}
          />
        </Header>

        <LoadingPanel layout={layout} mt="64px" ml="0px" mr="0px" mb="0px" />
        <Content
          style={{
            padding: "15px",
            marginTop: "10px",
            backgroundColor: "#485C7E",
          }}
        >
          <div className={`site-main  p-5 bg-white`} style={{ border: "solid 1px #aaa" }}>
            <Row gutter={[0, 0]} align="top" justify="start" style={{ position: "relative", marginBottom: "10px" }}>
              <Col xl={12} xs={24}>
                <h2 style={{ color: "#1c78bc" }}>
                  {(curSubPage && curSubPage.label) || (curPage && curPage.label) || (curModule && curModule.label)}
                </h2>
              </Col>
              <Col xl={12} xs={24}>
                <Breadcrumb className={layout === "full" || layout === "small" ? "text-right" : ""}>
                  {curModule && (
                    <Breadcrumb.Item>
                      <a className="html-link" onClick={() => handlePathChanged(curModule.path)} className="text-bold">
                        {curModule.label}
                      </a>
                    </Breadcrumb.Item>
                  )}
                  {curPage && <Breadcrumb.Item>{curPage.label}</Breadcrumb.Item>}
                  {curSubPage && <Breadcrumb.Item>{curSubPage.label}</Breadcrumb.Item>}
                </Breadcrumb>
              </Col>
            </Row>
            <Row gutter={[0, 0]} align="top" justify="start" style={{ minHeight: "800px" }}>
              <Col span={24}>
                {props.routes
                  .filter((r) => !r.roles || r.roles.includes(userRole))
                  .map((route, i) => (
                    <ProtectedRoute
                      key={`route_${i}`}
                      exact={true}
                      path={`/${route.url}`}
                      render={(props) => <route.component key={`rc_${i}`} {...props} />}
                    />
                  ))}
                <Route exact={true} path="/app/" render={() => <Redirect to={{ pathname: "/app/e-docs" }} />} />
                <Route exact={true} path="/" render={() => <Redirect to={{ pathname: "/app/e-docs" }} />} />
              </Col>
            </Row>

            {/* <Route path="/app/" render={() => <Redirect to={{ pathname: "/app/error-404" }} />} /> */}
            <Footer style={{ textAlign: "center", position: "relative", marginTop: "0px", bottom: "0" }}>
              <Text type="secondary">
                <small>
                  EDGE ECP © 2021 - <b>({environment.NAME})</b>
                </small>
              </Text>
            </Footer>
          </div>
          {/* <Footer style={{ textAlign: "center", position: "relative", marginTop: "0px", bottom: "0" }}>
            <Text type="secondary">
              <small>
                EDGE ECP © 2021 - <b>({environment.NAME})</b>
              </small>
            </Text>
          </Footer> */}
        </Content>
      </Layout>
    </>
  );
};

export default ProtectedLayout;
