import { FormButtons } from "Components/Common/FormButtons";
import { defaultUser } from "Components/Common/Images/Images";
import ApiUploader from "Components/Common/ApiUploader";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import LookupService from "Services/API/LookupService";
import moment from "moment";
import { QuestionCircleFilled, SearchOutlined } from "@ant-design/icons";
import AuthService from "Services/AuthService";
import Uploader from "./DocsUploader";
import DocsUploader from "./DocsUploader";

const {
  Modal,
  Form,
  Input,
  Checkbox,
  Select,
  Button,
  Image,
  Switch,
  DatePicker,
  Row,
  Col,
  Tooltip,
  Space,
  Divider,
} = require("antd");

const Form_DocsSearch = ({
  form,
  showForm,
  searchFormFilters,
  handleCancel,
  handleUploadSuccess,
  onSearch,
  onSearchFailed,
  ENTITY,
  data,
}) => {
  const [principal, setPrincipal] = useState(0);

  useEffect(async () => {
    if (searchFormFilters && searchFormFilters.principal) {
      setPrincipal(searchFormFilters.principal);

      searchFormFilters.docType = searchFormFilters.docType;
      searchFormFilters.archived = false;
      searchFormFilters.unArchived = true;
    }

    let pNum = AuthService.PrincipalNbr();
    if (pNum !== 0) {
      setPrincipal(pNum);
    } else {
      pNum = LookupService.GetAppPrincipal();
      setPrincipal(pNum);
    }
  }, [searchFormFilters]);

  useEffect(async () => {
    if (principal > 0) {
      await handleSearch(form.getFieldsValue());
    }
    //LookupService.SetAppPrincipal(principal);
  }, [principal]);

  const handleSearch = async (values) => {
    const frmValues = values || form.getFieldsValue();
    if (!frmValues.principal || frmValues.principal === 0) {
      frmValues.principal = principal;
      frmValues.unArchived = true;
    }

    frmValues.docType = frmValues.docType || "External";
    frmValues.archived = frmValues.archived || false;

    await onSearch(frmValues);
  };

  const resetForm = () => {
    const frmValues = form.getFieldsValue();
    // frmValues.fleetnames = [];
    form.setFieldsValue(frmValues);
  };

  return (
    <Row style={{ width: "100%" }}>
      <Col span={24}>
        {/* <Space direction="vertical"> */}
        <Form
          form={form}
          name={`form-${ENTITY}`}
          layout="inline"
          initialValues={searchFormFilters}
          labelCol={{}}
          wrapperCol={{}}
          autoComplete="off"
          size="middle"
          onFinish={handleSearch}
          onFinishFailed={onSearchFailed}
        >
          <Form.Item name="docType" hidden />
          <Form.Item name="unArchived" hidden />

          <Form.Item name="principal" label="Principal">
            {AuthService.isEdgeUser() && (
              <Select
                showSearch
                style={{ width: "400px" }}
                placeholder="Select Principals"
                optionFilterProp="children"
                options={data.principals}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(p) => {
                  setPrincipal(p);
                  resetForm();
                  LookupService.SetAppPrincipal(p);
                }}
                defaultValue={LookupService.GetAppPrincipal()}
              ></Select>
            )}
            {AuthService.isPrincipalUser() &&
              data.principals &&
              data.principals.map((p, i) => {
                return (
                  <h3 key={`principal_${i}`} className="text-bold text-warning">
                    {p.label}
                  </h3>
                );
              })}
          </Form.Item>

          <Form.Item name="archived" valuePropName="checked">
            <Checkbox>Show Archives</Checkbox>
          </Form.Item>
          <Form.Item>
            <Tooltip title="By selecting 'Show Archives' option, only the documents where uploaded date is older then 24 months, will be shown in results">
              <QuestionCircleFilled className="text-larger text-disabled" />
            </Tooltip>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={!principal || principal === 0}>
              <SearchOutlined /> Filter
            </Button>
          </Form.Item>
        </Form>
        <br />
        {/* <Divider /> */}
        {AuthService.isEdgeUser() && searchFormFilters && searchFormFilters.principal !== 0 && (
          <DocsUploader
            principal={searchFormFilters.principal}
            docType={searchFormFilters.docType}
            handleUploadSuccess={handleUploadSuccess}
          />
        )}
        {/* </Space> */}
      </Col>
    </Row>
  );
};

export default Form_DocsSearch;
