import "./assets/styles/antd-custom.less";
import "./App.css"; //dont move it aboe antd css
import { Router, Route, Switch, Redirect } from "react-router-dom";
//import { withResizeDetector } from "react-resize-detector";
import { useEffect, useState } from "react";

import { createBrowserHistory } from "history";
import { routes } from "./Routes.js";
import ProtectedLayout from "./Layout/ProtectedLayout";
import PublicLayout from "./Layout/PublicLayout";
import AuthService from "./Services/AuthService";

import Navigation from "Data/Navigation.json";

const history = createBrowserHistory();

const App = ({ hideLoader }) => {
  const [layout, setLayout] = useState("full");
  //const [userRole, setUserRole] = useState();
  const [filteredNavigation, setFilteredNavigation] = useState(null);

  useEffect(async () => {
    hideLoader();
    handleResize();
    window.addEventListener("resize", handleResize);

    //setUserRole(AuthService.getUserRole());
    let userRole = AuthService.getUserRole();
    let userModules = await AuthService.getPrincipalUserModules();
    setFilteredNavigation(filterNavigation(userRole, userModules));

    return () => {
      //cleanup;
    };
  }, []);

  const handleResize = () => {
    //console.log("APP Width", window.innerWidth);
    setLayout(
      window.innerWidth >= 1366
        ? "full"
        : window.innerWidth <= 1365 && window.innerWidth >= 1024
        ? "small"
        : window.innerWidth <= 1023 && window.innerWidth >= 768
        ? "mini"
        : "overlay"
    );
  };

  function filterNavigation(uRole, uModules) {
    let nav = Navigation.filter((n) => 1 === 1 && (!n.roles || n.roles.includes(uRole))).map((m) => {
      return { ...m, items: m.items && m.items.filter((sm) => !sm.roles || sm.roles.includes(uRole)) };
    });

    if ((uRole === "Principal Admin" || uRole === "Principal User") && uModules != null) {
      nav = nav.filter(
        (n) =>
          (n.label !== "External" || uModules.includes("External")) &&
          (n.label !== "Mortgagees" || uModules.includes("Mortgagees")) &&
          (n.label !== "Claims" || uModules.includes("Claims")) &&
          (n.label !== "Dialogue" || uModules.includes("Dialogues"))
      );
    }

    return nav;
  }

  const publicRoutes = routes.filter((r) => r.auth === "public");
  const protectedRoutes = routes.filter((r) => r.auth !== "public");
  //const landingRoute = [{ url: "/", auth: "public", component: Landing }];

  const isLoggedIn = AuthService.isLoggedIn();
  const userRole = AuthService.getUserRole();

  return (
    <>
      <Router history={history}>
        <Switch>
          {isLoggedIn && filteredNavigation !== null && (
            <ProtectedLayout
              layout={layout}
              routes={protectedRoutes}
              history={history}
              userRole={userRole}
              navigation={filteredNavigation}
            />
          )}
          <PublicLayout layout={layout} routes={publicRoutes} history={history} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
//export default withResizeDetector(App);
