import React, { useState, useEffect } from "react";

import { trackPromise } from "react-promise-tracker";
import { notify } from "Services/ToastService";

import { Col, Modal, Form, Button } from "antd";
import { CloseCircleFilled, CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";

import LookupService from "Services/API/LookupService";
import EdocService from "Services/API/EdocService";
import Form_EDocs from "./Form_EDocs";
import { getLookupDataSource } from "Lib/ReactHelper";

import AuthService from "Services/AuthService";
import CustomDataGrid from "Components/DevEx/CustomDataGrid";
import PdfPreview from "Components/Common/PdfPreview";
import { getBase64FromFile } from "Lib/JsHelper";
import { FormButtons } from "Components/Common/FormButtons";

var DG;

const EDocs = (props) => {
  const ENTITY = "E-Doc";
  const ENTITY_PLURAL = "E-Docs";
  const PK_FIELD = "ReportId";

  const [form] = Form.useForm();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState();

  const [principals, setPrincipals] = useState();

  const [docs, setDocs] = useState();
  const [principal, setPrincipal] = useState(0);
  const [fleetNames, setFleetNames] = useState();
  const [vessels, setVessels] = useState();
  const [interests, setInterests] = useState();
  const [managers, setManagers] = useState();
  const [docTypes, setDocTypes] = useState();
  const [sortFields, setSortFields] = useState();
  const [policyFrom, setPolicyFrom] = useState();
  const [policyTo, setPolicyTo] = useState();

  const [sortField1, setSortField1] = useState("Intltext");
  const [sortField2, setSortField2] = useState("Insfdate");

  const [pdfFileToPreview, setPdfFileToPreview] = useState();

  useEffect(async () => {
    let pData = await trackPromise(LookupService.Principals());
    if (pData.length > 1) {
      let allP = { label: "- Select a principal -", value: 0 };
      const p = [{ ...allP }, ...pData];
      setPrincipals(p);
    } else {
      setPrincipals(pData);
    }

    //await handleSearch(0);
  }, []);

  const handleEdit = async (item) => {
    //console.log("Edit Handler", item);
    setRecordToEdit(item[PK_FIELD] ? item : null);
    setShowEditForm(true);
  };

  const handleCancel = () => {
    setShowEditForm(false);
  };

  const onFinish = async (values) => {
    //console.log(files);

    /* IF FILES NEED TO BE UPLOADED VIA FORM
    const formData = new FormData();
     files.forEach((file) => {
       formData.append("files[]", file);
     });
    */
    let record = values;
    console.log("form values", values);

    //SET SOME DEFAULT VALUES HERE
    // if (logoImage) {
    //   record.business_logo = logoImage.base64.substring(logoImage.base64.indexOf(",") + 1);
    // }
    //record.is_default = false;
    record.principal = record.principal || AuthService.PrincipalNbr();

    const { data, status, message } = await trackPromise(EdocService.Edoc_List(values));

    if (status) {
      setDocs(data.docs);
      setPrincipal(data.Principal);
      setDocTypes(getLookupDataSource(data.DocTypes));
      setVessels(getLookupDataSource(data.Vessels));
      setInterests(getLookupDataSource(data.Interests));
      setManagers(getLookupDataSource(data.Managers));
      setFleetNames(getLookupDataSource(data.FleetNames));
      setSortFields(getLookupDataSource(data.SortFields, "label", "value"));
      setPolicyFrom(data.PolicyFrom);
      setPolicyTo(data.PolicyTo);

      // setSortField1(values.sortField1);
      // setSortField2(values.sortField2);

      record.principal = data.Principal;
      record.policyTo = data.PolicyTo;
      record.policyFrom = data.PolicyFrom;
      setRecordToEdit(record);
    }
  };
  const onFinishFailed = async () => {
    notify("Please provide all the required input fields", false);
  };

  const handleDelete = async (ids) => {
    //let ids = selectedRows;
    const { data, status, message } = await trackPromise(EdocService.Edoc_Delete(ids));

    notify(message, status);
    if (status) {
      onFinish({
        principal: principal,
        policyFrom: policyFrom,
        policyTo: policyTo,
      });
    }
  };

  const download = async (doc) => {
    const { data, status, message } = await trackPromise(EdocService.Edoc_Download(doc));

    notify(message, status);
    if (status) {
      var fileDownload = require("js-file-download");
      fileDownload(data, `${doc.Objname}-${doc.Rptcode}.pdf`);
    }
  };

  const preview = async (doc) => {
    // if (doc.DownloadName.substring(doc.DownloadName.lastIndexOf(".") + 1).toLowerCase() != "pdf") {
    //   notify("Only PDF files can be previewed", false);
    //   return;
    // }

    const { data, status, message } = await trackPromise(EdocService.Edoc_Download(doc));

    !status && notify("Failed to get this file at this time", status);

    if (status && data) {
      setShowPreview(true);
      let fileBase64 = "";
      getBase64FromFile(data, (result) => {
        fileBase64 = result;
        setPdfFileToPreview(fileBase64);
      });
    }
  };

  const columns = [
    {
      dataField: "Objname",
      caption: "Vessel",
      alignment: "left",
      dataType: "string",
    },
    {
      dataField: "Intltext",
      caption: "Interest",
      alignment: "left",
      dataType: "string",
    },
    {
      dataField: "Rptcode",
      caption: "Document Type",
      alignment: "left",
      dataType: "string",
      cellRender: (item) => {
        return <span title={item.data.PathToDocNew}>{item.data.Rptcode}</span>;
      },
    },
    {
      dataField: "Insfdate",
      caption: "Inception",
      alignment: "right",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "Instdate",
      caption: "Expiry",
      alignment: "right",
      dataType: "date",
      format: "dd/MM/yyyy",
    },
    {
      dataField: "ClientName",
      caption: "Client Name",
      alignment: "left",
      dataType: "string",
    },
    { dataField: "Fleetname", caption: "Fleet", alignment: "left", dataType: "string" },
    {
      caption: "Preview",
      dataType: "string",
      alignment: "center",
      width: "50px",
      allowHeaderFiltering: false,
      headerCellRender: () => {
        return <></>;
      },
      fixed: true,
      fixedPosition: "right",
      cellRender: (item) => {
        return (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                preview(item.data);
              }}
              style={{
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                fontSize: "18px",
              }}
              className="text-primary"
            >
              <EyeOutlined title="Preview" />
            </button>
          </>
        );
      },
    },
    {
      caption: "Download",
      alignment: "center",
      width: "70px",
      headerCellRender: () => {
        return <></>;
      },
      fixed: true,
      fixedPosition: "right",
      cellRender: (item) => {
        return (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                download(item.data);
              }}
              style={{ border: "none", backgroundColor: "transparent", cursor: "pointer", fontSize: "18px" }}
              className="text-success"
            >
              <CloudDownloadOutlined title="Download" className="text-bold text-large" />
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex  box box-pad bg-grey">
        <Form_EDocs
          form={form}
          showForm={showEditForm}
          recordToEdit={recordToEdit}
          handleCancel={handleCancel}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          ENTITY={ENTITY}
          data={{ principals, vessels, fleetNames, managers, docTypes, interests, sortFields }}
        />
      </div>

      <div className="mt-5 pt-3">
        {docs && (
          <CustomDataGrid
            data={docs}
            columns={columns}
            PK_FIELD="PK"
            ENTITY={ENTITY}
            ENTITY_PLURAL={ENTITY_PLURAL}
            editHandler={handleEdit}
            deleteHandler={handleDelete}
            bulkDeleteHandler={handleDelete}
            canEdit={false}
            canDelete={false}
            canSelect={AuthService.isEdgeUser()}
            canBulkDelete={AuthService.isEdgeUser()}
            doPaging={false}
            clearFiltersOnSearch={true}
          />
        )}
      </div>

      <Modal
        width={768}
        closeIcon={<CloseCircleFilled style={{ fontSize: "28px" }} />}
        title=""
        visible={showPreview}
        // onOk={handleSave}
        onCancel={() => {
          setShowPreview(false);
        }}
        footer={
          <div />
          //   [
          //   <Button
          //     className="ml-auto mr-auto"
          //     size="large"
          //     onClick={() => {
          //       setShowPreview(false);
          //     }}
          //   >
          //     Close
          //   </Button>,
          // ]
        }
      >
        <Col span={24}>
          <PdfPreview file={pdfFileToPreview} />
        </Col>
      </Modal>
    </>
  );
};

export default EDocs;
