import React, { useState, useEffect } from "react";

import { trackPromise } from "react-promise-tracker";
import { notify } from "Services/ToastService";

import {
  Button,
  Select,
  Row,
  Col,
  Image,
  Card,
  Input,
  Modal,
  Upload,
  Form,
  Space,
  Tooltip,
  Popconfirm,
  Checkbox,
} from "antd";
import { CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";

import LookupService from "Services/API/LookupService";
import { MoneyFormat } from "Lib/DevExConstants";
import moment from "moment";
import DocService from "Services/API/DocService";
import { getLookupDataSource } from "Lib/ReactHelper";
import AuthService from "Services/AuthService";
import CustomDataGrid from "Components/DevEx/CustomDataGrid";
import PdfPreview from "Components/Common/PdfPreview";
import { getBase64FromFile } from "Lib/JsHelper";
import Form_DocsUpdate from "./Form_DocsUpdate";
import Form_DocsSearch from "./Form_DocsSearch";

const DocsViewer = ({ docType }) => {
  const ENTITY = `${docType} Document`;
  const ENTITY_PLURAL = `${docType} Documents`;
  const PK_FIELD = "DocId";

  const [searchForm] = Form.useForm();
  const [editForm] = Form.useForm();

  const [showEditForm, setShowEditForm] = useState(false);

  const [searchFormFilters, setSearchFormFilters] = useState({
    principal: 0,
    docType: docType,
    archived: false,
    unArchived: true,
  });
  const [recordToEdit, setRecordToEdit] = useState();

  const [principals, setPrincipals] = useState();

  const [docs, setDocs] = useState();
  const [principal, setPrincipal] = useState(0);
  const [pdfFileToPreview, setPdfFileToPreview] = useState();

  // const [selectedRows, setSelectedRows] = useState();
  useEffect(async () => {
    let pData = await trackPromise(LookupService.Principals());
    if (pData.length > 1) {
      let allP = { label: "- Select a principal -", value: 0 };
      const p = [{ ...allP }, ...pData];
      setPrincipals(p);
    } else {
      setPrincipals(pData);
    }
  }, []);

  const handleEdit = async (item) => {
    //console.log("Edit Handler", item);
    setRecordToEdit(item[PK_FIELD] ? item : null);
    setShowEditForm(true);
  };

  const handleCancel = () => {
    setShowEditForm(false);
  };

  const onSearch = async (values) => {
    let record = values;
    console.log("form values", values);

    //SET SOME DEFAULT VALUES HERE
    // if (logoImage) {
    //   record.business_logo = logoImage.base64.substring(logoImage.base64.indexOf(",") + 1);
    // }
    //record.is_default = false;
    record.principal = record.principal || AuthService.PrincipalNbr();
    setPrincipal(record.principal);
    const { data, status, message } = await trackPromise(DocService.Doc_List(values));

    if (status) {
      setDocs(data);

      setSearchFormFilters(record);
    }
    setPdfFileToPreview(null);
  };

  const onSave = async (values) => {
    let record = values;
    console.log("form values", values);

    //SET SOME DEFAULT VALUES HERE
    // if (logoImage) {
    //   record.business_logo = logoImage.base64.substring(logoImage.base64.indexOf(",") + 1);
    // }
    //record.is_default = false;
    //record.principal = record.principal || AuthService.PrincipalNbr();
    //setPrincipal(record.principal);
    const { data, status, message } = await trackPromise(DocService.Doc_Save(values));

    if (status) {
      let d = docs.map((item) => {
        if (item.DocId === record.DocId) {
          const updatedItem = {
            ...item,
            Vessel: record.Vessel,
            InsuranceType: record.InsuranceType,
            DocumentType: record.DocumentType,
            InceptionDate: record.InceptionDate,
            EffectiveDate: record.EffectiveDate,
          };
          switch (record.DocType.toLowerCase()) {
            case "external": {
              updatedItem.DownloadName = `${record.Vessel} - ${record.InsuranceType} - ${
                record.DocumentType
              } - ${moment(record.InceptionDate).format("dd/MM/yyyy")}${record.DownloadName.substring(
                record.DownloadName.lastIndexOf(".")
              )}`;
              break;
            }
            case "mortgagee": {
              updatedItem.DownloadName = `${record.Vessel} -${record.DocumentType} - ${moment(
                record.EffectiveDate
              ).format("dd/MM/yyyy")}${record.DownloadName.substring(record.DownloadName.lastIndexOf("."))}`;
              break;
            }
            case "dialogue": {
              break;
            }
          }
          return updatedItem;
        }
        return item;
      });

      setDocs(d);

      setRecordToEdit({});
      setShowEditForm(false);
    }

    setPdfFileToPreview(null);
  };

  const onFinishFailed = async () => {
    notify("Please provide all the required input fields", false);
  };

  const handleDelete = async (ids) => {
    const { data, status, message } = await trackPromise(DocService.Doc_Delete(ids));

    notify(message, status);
    if (status) {
      onSearch(searchFormFilters);
    }
  };

  const download = async (doc) => {
    const { data, status, message, filename } = await trackPromise(DocService.Doc_Download(doc.DocId));

    !status && notify(message, status);
    if (status) {
      var fileDownload = require("js-file-download");
      fileDownload(data, `${filename}`);
    }
  };

  const preview = async (doc) => {
    if (doc.DownloadName.substring(doc.DownloadName.lastIndexOf(".") + 1).toLowerCase() != "pdf") {
      notify("Only PDF files can be previewed", false);
      return;
    }
    const { data, status, message, filename } = await DocService.Doc_Download(doc.DocId);

    !status && notify("Failed to get this file at this time", status);
    if (status && data) {
      let fileBase64 = "";
      getBase64FromFile(data, (result) => {
        fileBase64 = result;
        setPdfFileToPreview(fileBase64);
      });
    }
  };

  const handleUploadSuccess = (status, message) => {
    notify(message, status);
    onSearch(searchFormFilters);
  };

  function getEditAccess() {
    if (AuthService.isEdgeUser()) {
      return docType === "Dialogue" ? false : true;
    }
    return false;
  }

  const columns = [
    {
      caption: "File Name",
      dataField: "DownloadName",
      dataType: "string",
      width: "200px",
    },
    {
      caption: "Uploaded",
      dataField: "UploadedDate",
      dataType: "date",
      alignment: "center",
      format: "dd/MM/yyyy",
      width: "130px",
    },
    {
      caption: "Vessel",
      dataField: "Vessel",
      dataType: "string",
      visible: docType === "External" || docType === "Mortgagee",
    },
    {
      caption: "Insurance",
      dataField: "InsuranceType",
      dataType: "string",
      visible: docType === "External" || false,
    },
    {
      caption: "Doc. Type",
      dataField: "DocumentType",
      dataType: "string",
      visible: docType === "External" || docType === "Mortgagee",
    },
    {
      caption: "Inception",
      dataField: "InceptionDate",
      dataType: "date",
      alignment: "center",
      format: "dd/MM/yyyy",
      width: "110px",
      visible: docType === "External",
    },
    {
      caption: "Effective ",
      dataField: "EffectiveDate",
      dataType: "date",
      alignment: "center",
      format: "dd/MM/yyyy",
      width: "110px",
      visible: docType === "Mortgagee",
    },

    {
      caption: "Preview",
      dataType: "string",
      alignment: "center",
      width: "50px",
      allowHeaderFiltering: false,
      headerCellRender: () => {
        return <></>;
      },
      fixed: true,
      fixedPosition: "right",
      cellRender: (item) => {
        return (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                preview(item.data);
              }}
              style={{
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                fontSize: "18px",
              }}
              className="text-primary"
            >
              <EyeOutlined title="Preview" />
            </button>
          </>
        );
      },
    },
    {
      caption: "Download",
      dataType: "string",
      alignment: "center",
      width: "50px",
      allowHeaderFiltering: false,
      headerCellRender: () => {
        return <></>;
      },
      fixed: true,
      fixedPosition: "right",
      cellRender: (item) => {
        return (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                download(item.data);
              }}
              style={{ border: "none", backgroundColor: "transparent", cursor: "pointer", fontSize: "18px" }}
              className="text-success"
            >
              <CloudDownloadOutlined title="Download" />
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex  box box-pad bg-grey mb-5">
        <Form_DocsSearch
          form={searchForm}
          showForm={true}
          searchFormFilters={searchFormFilters}
          handleCancel={handleCancel}
          handleUploadSuccess={handleUploadSuccess}
          onSearch={onSearch}
          onSearchFailed={onFinishFailed}
          ENTITY={ENTITY}
          data={{ principals }}
        />
      </div>

      <Row gutter={10}>
        <Col span={14}>
          <div className="mt-5 pt-3">
            {docs && (
              <CustomDataGrid
                data={docs}
                columns={columns}
                PK_FIELD={PK_FIELD}
                ENTITY={ENTITY}
                ENTITY_PLURAL={ENTITY_PLURAL}
                editHandler={handleEdit}
                deleteHandler={handleDelete}
                bulkDeleteHandler={handleDelete}
                canEdit={getEditAccess()}
                canDelete={false}
                canSelect={AuthService.isEdgeUser()}
                canBulkDelete={AuthService.isEdgeUser()}
              />
            )}
          </div>
        </Col>
        {docs && (
          <Col span={10} style={{ marginTop: "80px" }}>
            <PdfPreview file={pdfFileToPreview} />
          </Col>
        )}
      </Row>
      {showEditForm && (
        <Form_DocsUpdate
          form={editForm}
          showForm={showEditForm}
          recordToEdit={recordToEdit}
          handleCancel={handleCancel}
          onFinish={onSave}
          onFinishFailed={onFinishFailed}
          ENTITY={ENTITY}
          data={{ principals }}
        />
      )}
    </>
  );
};

export default DocsViewer;
