import { FormButtons } from "Components/Common/FormButtons";
import { defaultUser } from "Components/Common/Images/Images";
import ApiUploader from "Components/Common/ApiUploader";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import LookupService from "Services/API/LookupService";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import AuthService from "Services/AuthService";
import { subMonths } from "date-fns";
import { endOfYear } from "date-fns/esm";

const { Modal, Form, Input, Checkbox, Select, Button, Image, Switch, DatePicker, Row, Col, Tooltip } = require("antd");

const Form_EDocs = ({ form, showForm, recordToEdit, handleCancel, onFinish, onFinishFailed, ENTITY, data }) => {
  const [principal, setPrincipal] = useState(0);
  // const [fleetNames, setFleetnames] = useState();
  // const [vessels, setVessels] = useState();
  // const [interests, setInterests] = useState();
  // const [managers, setManagers] = useState();
  // const [docTypes, setTypes] = useState();
  // const [sortFields, setSortFields] = useState();

  useEffect(async () => {
    //if (data && data.principals) setPrincipals(data.principals);
    if (recordToEdit && recordToEdit.principal) {
      //get related data from DB here (if needed)
      setPrincipal(recordToEdit.principal);
      //form.resetFields();
    }

    let pNum = AuthService.PrincipalNbr();
    if (pNum !== 0) {
      setPrincipal(pNum);
    } else {
      pNum = LookupService.GetAppPrincipal();
      setPrincipal(pNum);
    }
  }, [recordToEdit]);

  useEffect(() => {
    if (principal > 0) {
      handleSearch();
    }
    LookupService.SetAppPrincipal(principal);
  }, [principal]);

  const handleSearch = () => {
    const frmValues = form.getFieldsValue();
    if (!frmValues.principal || frmValues.principal === 0) {
      frmValues.principal = principal;
    }
    onFinish(frmValues);
    // onFinish({
    //   principal: principal,
    //   policyFrom: form.getFieldValue("policyFrom"),
    //   policyTo: form.getFieldValue("policyTo"),
    // });
  };

  const resetForm = () => {
    const frmValues = form.getFieldsValue();
    frmValues.fleetnames = [];
    frmValues.vessels = [];
    frmValues.interests = [];
    frmValues.managers = [];
    frmValues.docTypes = [];
    frmValues.sortField1 = "";
    frmValues.sortField2 = "";
    form.setFieldsValue(frmValues);
  };

  let default_psd = subMonths(new Date(), 19);
  //let default_ped = endOfYear(new Date());
  let default_ped = new Date();
  return (
    <Form
      form={form}
      name={`form-${ENTITY}`}
      layout="vertical"
      initialValues={recordToEdit}
      labelCol={{}}
      wrapperCol={{}}
      autoComplete="off"
      size="middle"
      onFinish={handleSearch}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={24}>
        <Col xs={20} xl={8}>
          <Form.Item name="principal" label="Principal">
            {AuthService.isEdgeUser() && (
              <Select
                showSearch
                //style={{ width: "100%" }}
                placeholder="Select Principals"
                optionFilterProp="children"
                options={data.principals}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(p) => {
                  setPrincipal(p);
                  resetForm();
                  LookupService.SetAppPrincipal(p);
                }}
                defaultValue={LookupService.GetAppPrincipal()}
              ></Select>
            )}
            {AuthService.isPrincipalUser() &&
              data.principals &&
              data.principals.map((p, i) => {
                return (
                  <h3 className="text-warning" key={`principal_${i}`}>
                    {p.label}
                  </h3>
                );
              })}
          </Form.Item>
        </Col>
        <Col xs={20} xl={8}>
          {" "}
          <Form.Item name="fleetnames" label="Fleet">
            <Select
              showSearch
              mode="multiple"
              //style={{ width: "100%" }}
              placeholder="Select Fleet"
              optionFilterProp="children"
              options={data.fleetNames}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={20} xl={8}>
          {" "}
          <Form.Item name="vessels" label="Vessel">
            <Select
              showSearch
              mode="multiple"
              //style={{ width: "100%" }}
              placeholder="Select Vessel"
              optionFilterProp="children"
              options={data.vessels}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={20} xl={8}>
          <Form.Item name="managers" label="Technical Manager">
            <Select
              showSearch
              mode="multiple"
              //style={{ width: "100%" }}
              placeholder="Select Technical Manager"
              optionFilterProp="children"
              options={data.managers}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={20} xl={8}>
          <Form.Item name="interests" label="Interest">
            <Select
              showSearch
              mode="multiple"
              //style={{ width: "100%" }}
              placeholder="Select Interest"
              optionFilterProp="children"
              options={data.interests}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </Form.Item>
        </Col>
        <Col xs={20} xl={8}>
          <Form.Item name="docTypes" label="Document Type">
            <Select
              showSearch
              mode="multiple"
              //style={{ width: "100%" }}
              placeholder="Select document type"
              optionFilterProp="children"
              options={data.docTypes}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </Form.Item>
        </Col>
        {/* <Col xs={20} xl={8}>
          <Form.Item name="sortField1" label="Sort by" style={{ display: "inline-block", width: "calc(50% - 1px)" }}>
            <Select
              showSearch
              //style={{ width: "100%" }}
              placeholder="Select a field"
              optionFilterProp="children"
              options={data.sortFields}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </Form.Item>
          <Form.Item name="sortField2" label="Then" style={{ display: "inline-block", width: "calc(49% - 1px)" }}>
            <Select
              showSearch
              //style={{ width: "100%" }}
              placeholder="Select a field"
              optionFilterProp="children"
              options={data.sortFields}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            ></Select>
          </Form.Item>
        </Col> */}
        <Col xs={20} xl={8}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              name="policyFrom"
              label="Policy From"
              initialValue={moment(default_psd)}
              style={{ display: "inline-block", width: "calc(50% - 1px)" }}
              rules={[{ required: true, message: "Please input policy-from date!" }]}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>

            <Form.Item
              name="policyTo"
              label="Policy To"
              initialValue={moment(default_ped)}
              style={{ display: "inline-block", width: "calc(49% - 1px)" }}
              rules={[{ required: true, message: "Please input policy-to date!" }]}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col xs={20} xl={8}>
          <Form.Item label="" style={{ marginTop: "25px" }}>
            <Tooltip title="In order to filter the data, you must select a Principal first">
              <Button type="primary" htmlType="submit" disabled={!principal || principal === 0}>
                <SearchOutlined /> Filter
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              type="default"
              onClick={() => {
                resetForm();
              }}
            >
              Clear
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Form_EDocs;
