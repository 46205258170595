import { FormButtons } from "Components/Common/FormButtons";
import { defaultUser } from "Components/Common/Images/Images";
import ApiUploader from "Components/Common/ApiUploader";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import LookupService from "Services/API/LookupService";
import moment from "moment";

const { Modal, Form, Input, Checkbox, Select, Button, Image, Switch, DatePicker } = require("antd");
const { LoadingPanelForPopup } = require("Layout/LoadingPanels");

const Form_DocsUpdate = ({ form, showForm, recordToEdit, handleCancel, onFinish, onFinishFailed, ENTITY }) => {
  const [type, setType] = useState();
  useEffect(() => {
    ENTITY && setType(ENTITY.substring(0, ENTITY.indexOf(" ")).trim());
  }, [ENTITY]);
  useEffect(async () => {
    //if (data && data.principals) setPrincipals(data.principals);
    if (recordToEdit) {
      //get related data from DB here (if needed)
      recordToEdit.InceptionDate = recordToEdit.InceptionDate ? moment(recordToEdit.InceptionDate) : null;
      recordToEdit.EffectiveDate = recordToEdit.EffectiveDate ? moment(recordToEdit.EffectiveDate) : null;
      form.resetFields();
    }
  }, [recordToEdit]);

  return (
    <Modal
      width={550}
      title={`Update ${ENTITY}`}
      visible={showForm}
      // onOk={handleSave}
      onCancel={handleCancel}
      footer={[<FormButtons {...{ form, handleCancel, ENTITY }} />]}
    >
      <LoadingPanelForPopup />
      <Form
        form={form}
        name={`form-${ENTITY}`}
        layout="vertical"
        initialValues={recordToEdit}
        labelCol={{}}
        wrapperCol={{}}
        autoComplete="off"
        size="middle"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="DocId" hidden />
        <Form.Item name="PrincipalNbr" hidden />
        <Form.Item name="DocType" hidden />
        <Form.Item name="DownloadName" hidden />
        <Form.Item name="UploadedDate" hidden />
        <Form.Item name="Vessel" label="Vessel" rules={[{ required: true, message: "Vessel is required" }]}>
          <Input placeholder="Vessel here" />
        </Form.Item>
        {type === "External" && (
          <Form.Item
            name="InsuranceType"
            label="Insurance Type"
            rules={[{ required: true, message: "Vessel is required" }]}
          >
            <Input placeholder="Insurance type here" />
          </Form.Item>
        )}
        <Form.Item
          name="DocumentType"
          label="Document Type"
          rules={[{ required: true, message: "Document type is required" }]}
        >
          <Input placeholder="Document type here" />
        </Form.Item>
        {type === "External" && (
          <Form.Item
            name="InceptionDate"
            label="Inception Date"
            rules={[{ required: true, message: "Inception date is required" }]}
          >
            {<DatePicker format="DD/MM/YYYY" placeholder="Select inception date" />}
          </Form.Item>
        )}
        {type === "Mortgagee" && (
          <Form.Item
            name="EffectiveDate"
            label="Effective Date"
            rules={[{ required: true, message: "Effective date is required" }]}
          >
            <DatePicker format="DD/MM/YYYY" placeholder="Select effective date" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default Form_DocsUpdate;
