import BaseApiService from "Services/BaseApiService";

class ClaimsService extends BaseApiService {
  //#region Claims
  static async Claim_List(principal) {
    const url = `/claims/claims/${principal || 0}`;
    return await super.GET(url);
  }

  static async Claim_Update(claim) {
    const url = `/claims/updateClaim`;
    const postData = claim;
    return await super.POST(url, postData);
  }
  //#endregion
}

export default ClaimsService;
