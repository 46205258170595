import BaseApiService from "Services/BaseApiService";

class EdocService extends BaseApiService {
  //#region Claims
  static async Edoc_List(filters) {
    const url = `/edocs/docs`;
    const postData = filters;
    return await super.POST(url, postData);
  }

  static async Edoc_Delete(ids) {
    const url = `/edocs/delete`;
    const postData = ids; //string[]
    return await super.POST(url, postData);
  }

  static async Edoc_Download(edoc) {
    const url = `/edocs/download`;
    const postData = edoc;
    return await super.DOWNLOAD(url, postData);
    //return await super.POST(url, postData);
  }
  //#endregion
}

export default EdocService;
