import AuthService from "Services/AuthService";
import { notify } from "Services/ToastService";

const { getLookupDataSource } = require("Lib/ReactHelper");
const { default: UserService } = require("./UserService");

class LookupService {
  static SetAppPrincipal(pid) {
    localStorage.setItem("app_principal", pid);
  }

  static GetAppPrincipal() {
    let pid = localStorage.getItem("app_principal");
    return (pid && parseInt(pid)) || 0;
  }

  static async Principals(defaultLabel, defaultValue) {
    // const { data, status } = await UserService.Principal_List();
    // //try to read from local storage
    // if (status) return getLookupDataSource(data, "PrincipalName", "PrincipalNbr", defaultLabel, defaultValue);

    try {
      let pCacheExpiry = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
      let pList = null;
      let uid = AuthService.getCurrentUser().id;
      let key_date = `pCacheExpiry_${uid}`;
      let key_entity = `principals_${uid}`;

      if (
        localStorage.getItem(key_date) === null ||
        localStorage.getItem(key_date) === undefined ||
        new Date() > new Date(localStorage.getItem(key_date))
      ) {
        const { data, status } = await UserService.Principal_List();

        !status && notify("Failed to load Principals list at this time", status);

        pList = getLookupDataSource(data, "PrincipalName", "PrincipalNbr", defaultLabel, defaultValue);

        localStorage.setItem(key_date, pCacheExpiry);
        localStorage.setItem(key_entity, JSON.stringify(pList));
      } else {
        pList = JSON.parse(localStorage.getItem(key_entity));
      }
      return pList;
    } catch (error) {
      notify("Failed to load principals. " + error, false);
      return [];
    }
  }

  static async UnassignedPrincipals(userId) {
    const { data, status } = await UserService.UnassignedPrincipal_List(userId);
    if (status) return getLookupDataSource(data, "PrincipalName", "PrincipalNbr");
  }

  static async PrincipalDocTypes(pid) {
    const { data, status } = await UserService.PrincipalDocTypes_List(pid);
    if (status) return getLookupDataSource(data, "Rptcode", "Rptcode");
  }

  static async PrincipalVessels(pid) {
    const { data, status } = await UserService.PrincipalVessels_List(pid);
    if (status) return getLookupDataSource(data, "Objname", "Imonum");
  }
  // static async PrincipalVessels(pid) {
  //   const { data, status } = await UserService.PrincipalVessels_List(pid);
  //   if (status) return getLookupDataSource(data, "Objname", "Imonum");
  // }
  // static async Industries(filter) {
  //   const { data, status } = await UserService.Industry_List(filter);
  //   if (status) return getLookupDataSource(data, "name", "id");
  // }

  // static async JobTags(filter) {
  //   const { data, status } = await UserService.JobTag_List(filter);
  //   if (status) return getLookupDataSource(data, "name", "id");
  // }

  // static async ServiceTypes(filter) {
  //   const { data, status } = await UserService.ServiceType_List(filter);
  //   if (status) return getLookupDataSource(data, "name", "id");
  // }

  // static async DiscountTags(filter) {
  //   const { data, status } = await UserService.DiscountTag_List(filter);
  //   if (status) return getLookupDataSource(data, "name", "id");
  // }

  // static BookingPageSections(filter) {
  //   const BookingPageSections_Data = require("Data/BookingPageSections.json");
  //   let data = getLookupDataSource(BookingPageSections_Data, "name", "id");
  //   return data;
  //}
}

export default LookupService;
