import React, { useState, useEffect } from "react";

import { trackPromise } from "react-promise-tracker";
import { notify } from "Services/ToastService";

import { Button, Row, Col, Image, Card, Input, Modal, Upload, Form, Space, Tooltip, Popconfirm, Checkbox } from "antd";
import { PlusCircleOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";

import CustomDataGrid from "Components/DevEx/CustomDataGrid";

import UserService from "Services/API/UserService";
import LookupService from "Services/API/LookupService";
import Form_PrincipalAdmin from "./Components/Form_PrincipalAdmin";
import moment from "moment";

const PrincipalAdmins = (props) => {
  const ENTITY = "Principal Admin";
  const ENTITY_PLURAL = "Principal Admins";
  const PK_FIELD = "UserId";

  const [form] = Form.useForm();
  const [showEditForm, setShowEditForm] = useState(false);

  const [data, setData] = useState();
  const [recordToEdit, setRecordToEdit] = useState();

  //const [unassignedPrincipals, setUnassignedPrincipals] = useState();
  // const [files, setFiles] = useState([]);
  // const [logoImage, setLogoImage] = useState();
  moment.tz.setDefault("UTC");
  useEffect(async () => {
    await handleSearch();
    //setUnassignedPrincipals(await trackPromise(LookupService.UnassignedPrincipals()));
    return () => {
      //setData([]);
    };
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [recordToEdit]);

  const handleSearch = async () => {
    let { data, status, message } = await trackPromise(UserService.PrincipalAdmin_List());
    notify(message, status);
    setData(data);
  };
  const handleEdit = async (item) => {
    //console.log("Edit Handler", item);
    item.PictureName = null;
    item.Role = "Principal Admin";
    item.Password = "";
    //setRecordToEdit(item[PK_FIELD] ? item : null);
    item.PublishedFrom = item.PublishedFrom ? moment(item.PublishedFrom) : moment("2018-01-01");
    item.PublishedTo = item.PublishedTo ? moment(item.PublishedTo) : moment("2021-12-31");
    item.SendPasswordEmail = true;
    setRecordToEdit(item);
    setShowEditForm(true);
  };

  const handleDelete = async (item) => {
    //console.log("Delete Handler", item);
    let { data, status, message } = await trackPromise(UserService.PrincipalAdmin_Delete(item[PK_FIELD]));
    notify(message, status);
    await handleSearch();
  };

  const handleCancel = () => {
    setShowEditForm(false);
  };

  const onFinish = async (values) => {
    //console.log(files);

    /* IF FILES NEED TO BE UPLOADED VIA FORM
    const formData = new FormData();
     files.forEach((file) => {
       formData.append("files[]", file);
     });
    */
    let record = values;
    console.log("form values", values);

    //SET SOME DEFAULT VALUES HERE
    // if (logoImage) {
    //   record.business_logo = logoImage.base64.substring(logoImage.base64.indexOf(",") + 1);
    // }
    //record.is_default = false;
    record.PublishedFrom = moment(record.PublishedFrom).parseZone().format("YYYY-MM-DD");
    record.PublishedTo = moment(record.PublishedTo).parseZone().format("YYYY-MM-DD");
    record.Role = "Principal Admin";

    const { data, status, message } = record[PK_FIELD]
      ? await trackPromise(UserService.PrincipalAdmin_Save(record))
      : await trackPromise(UserService.PrincipalAdmin_Save(record));

    notify(message, status);
    if (status) {
      //setLogoImage(null);
      setRecordToEdit({});
      setShowEditForm(false);
      handleSearch();
    }
  };
  const onFinishFailed = async () => {
    notify("Please provide all the required input fields", false);
  };

  const columns = [
    {
      caption: "Principal",
      dataField: "PrincipalName",
      dataType: "string",
    },
    {
      caption: "Username",
      dataField: "Username",
      dataType: "string",
      cellRender: (itemData) => {
        return <b>{itemData.text}</b>;
      },
      showSummary: true,
      summaryType: "count",
    },
    {
      caption: "Name",
      dataField: "FirstName",
      dataType: "string",
      cellRender: (item) => {
        let row = item.row.data;

        return `${row.UserId > 0 ? row.LastName + ", " + row.FirstName : ""} `;
      },
    },
    {
      caption: "Email",
      dataField: "Email",
      dataType: "string",
    },
    {
      caption: "External",
      dataField: "Edocs",
      dataType: "boolean",
      alignment: "center",
      width: "100px",
    },
    {
      caption: "Claims",
      dataField: "Claims",
      dataType: "boolean",
      alignment: "center",
      alignment: "center",
      width: "100px",
    },
    {
      caption: "Dialogue",
      dataField: "Ddocs",
      dataType: "boolean",
      alignment: "center",
      width: "100px",
    },
    {
      caption: "Mortgagees",
      dataField: "Mdocs",
      dataType: "boolean",
      alignment: "center",
      width: "100px",
    },
    {
      caption: "Active",
      dataField: "Active",
      dataType: "boolean",
      alignment: "center",
      width: "100px",
    },
  ];

  return (
    <>
      <div className="flex">
        <h3 style={{ marginBottom: "20px" }}> Manage {ENTITY_PLURAL}</h3>
        {/* <h3 className="push-right text-right">
          <Tooltip title={`Do you have more ${ENTITY_PLURAL}? Click here to add another`}>
            <Button className="bg-success" icon={<PlusCircleOutlined />} onClick={handleEdit}>
              Add new {ENTITY}
            </Button>
          </Tooltip>
        </h3> */}
      </div>
      <CustomDataGrid
        data={data}
        columns={columns}
        ENTITY={ENTITY}
        ENTITY_PLURAL={ENTITY_PLURAL}
        editHandler={handleEdit}
        deleteHandler={handleDelete}
        canEdit={true}
        canDelete={true}
      />

      {showEditForm && (
        <Form_PrincipalAdmin
          form={form}
          showForm={showEditForm}
          recordToEdit={recordToEdit}
          handleCancel={handleCancel}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          ENTITY={ENTITY}
          //data={{ unassignedPrincipals }}
        />
      )}
    </>
  );
};

export default PrincipalAdmins;
