import React, { useState, useEffect } from "react";

import { trackPromise } from "react-promise-tracker";
import { notify } from "Services/ToastService";

import {
  Button,
  Select,
  Row,
  Col,
  Image,
  Card,
  Input,
  Modal,
  Upload,
  Form,
  Space,
  Tooltip,
  Popconfirm,
  Checkbox,
} from "antd";
import { PlusCircleOutlined, EditOutlined, CloseOutlined, NodeExpandOutlined, EditFilled } from "@ant-design/icons";
import DataGrid, {
  Pager,
  Paging,
  Column,
  FilterRow,
  Export,
  ColumnChooser,
  Summary,
  TotalItem,
  Grouping,
  GroupPanel,
  ColumnFixing,
  StateStoring,
  HeaderFilter,
  LoadPanel,
  Sorting,
  Scrolling,
} from "devextreme-react/data-grid";

import LookupService from "Services/API/LookupService";
import ClaimsService from "Services/API/ClaimService";
import { MoneyFormat } from "Lib/DevExConstants";
import moment from "moment";
import ro from "date-fns/esm/locale/ro/index.js";
import Form_Claim from "./Form_Claim";
import AuthService from "Services/AuthService";
import CustomDataGrid from "Components/DevEx/CustomDataGrid";
import CurrencyFormat from "react-currency-format";
import NumberFormat from "react-number-format";

const Claims = (props) => {
  const ENTITY = "Claim";
  const ENTITY_PLURAL = "Claims";
  const PK_FIELD = "Clmnum";

  const [form] = Form.useForm();
  const [showEditForm, setShowEditForm] = useState(false);

  const [data, setData] = useState();
  const [recordToEdit, setRecordToEdit] = useState();

  const [principals, setPrincipals] = useState();
  const [principal, setPrincipal] = useState();
  const [principalName, setPrincipalName] = useState();

  const [claims, setClaims] = useState();

  useEffect(async () => {
    let pNum = AuthService.PrincipalNbr();

    let pData = await trackPromise(LookupService.Principals());
    let allP = { label: "---", value: 0 };
    const p = [{ ...allP }, ...pData];

    setPrincipals(p);
    if (pNum !== 0) {
      let pName = p.find((x) => x.value === parseInt(pNum)).label;
      setPrincipal(pNum);
      setPrincipalName(pName);
    }
  }, []);

  useEffect(async () => {
    if (principal) {
      await handleSearch(principal);
    } else if (AuthService.isEdgeUser()) {
      let p = LookupService.GetAppPrincipal();
      await handleSearch(p || 0);
    }
  }, [principal]);

  const handleSearch = async (p) => {
    console.log("selected principal", p);

    if (AuthService.isPrincipalUser() && p === 0) return;

    const { data } = await trackPromise(ClaimsService.Claim_List(p || 0));

    setClaims(data);
  };

  const handleEdit = async (item) => {
    //console.log("Edit Handler", item);
    setRecordToEdit(item[PK_FIELD] ? item : null);
    setShowEditForm(true);
  };

  const handleCancel = () => {
    setShowEditForm(false);
  };

  const onFinish = async (values) => {
    //console.log(files);

    /* IF FILES NEED TO BE UPLOADED VIA FORM
    const formData = new FormData();
     files.forEach((file) => {
       formData.append("files[]", file);
     });
    */
    let record = values;
    console.log("form values", values);

    //SET SOME DEFAULT VALUES HERE
    // if (logoImage) {
    //   record.business_logo = logoImage.base64.substring(logoImage.base64.indexOf(",") + 1);
    // }
    //record.is_default = false;

    const { data, status, message } = record[PK_FIELD]
      ? await trackPromise(ClaimsService.Claim_Update(record))
      : await trackPromise(ClaimsService.Claim_Update(record));

    notify(message, status);
    if (status) {
      //setLogoImage(null);
      setRecordToEdit({});
      setShowEditForm(false);
      handleSearch(principal);
    }
  };
  const onFinishFailed = async () => {
    notify("Please provide all the required input fields", false);
  };

  const renderCollectionHeader = () => {
    return (
      <div
        className="pt-1"
        style={{
          //backgroundColor: "#9CABCa",
          width: "380px",
          height: "30px",
          textAlign: "center",
          verticalAlign: "middle",
          border: "none",
        }}
      >
        <h3>Collection</h3>
      </div>
    );
  };

  const rowPrepared = (e) => {
    if (e.rowType === "data") {
      let item = e.data;
      let row = e.rowElement;
      row.className = `dx-row dx-data-row dx-row-lines dx-column-lines dx-row ${item.ClaimColor}`;
      //console.log("rowPrepared", e);
      //row.bgColor = item.ClaimColor;
    }
  };

  const IntlNfNO = new Intl.NumberFormat("da-DK", { style: "currency", currency: "NOK" });
  const Intl_NOK = (v) => IntlNfNO.format(v).replace("NOK", "");
  //use it like this:  Intl_NOK(amount))

  const columns = [
    {
      caption: "",
      alignment: "center",
      width: "80px",
      fixedPosition: "left",
      headerCellRender: () => {
        return <></>;
      },
      cellRender: (item) => {
        return (
          <>
            <Space size={20} align="center">
              <EditFilled
                title={`id: ${item.data[PK_FIELD]}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(item.data);
                }}
                className="text-primary text-large"
              />
            </Space>
          </>
        );
      },
    },
    {
      caption: "Collection",
      headerCellRender: renderCollectionHeader,
      children: [
        {
          dataField: "FinalTotalAmount",
          caption: "Total Amount",
          alignment: "right",
          dataType: "number",
          cellRender: (item) => {
            let d = item.data;
            return (
              (d.FinalTotalAmount && (
                <NumberFormat
                  value={d.FinalTotalAmount}
                  displayType={"text"}
                  thousandSeparator=" "
                  dicimalSeparator=","
                  isNumericString={false}
                />
              )) ||
              "0"
            );
          },
        },
        {
          dataField: "FinalDueDate",
          caption: "Due Date",
          alignment: "right",
          dataType: "datetime",
          format: "dd MM yyyy",
        },
        {
          dataField: "FinalPaid",
          caption: "Overdue",
          alignment: "right",
          dataType: "number",
          cellRender: (item) => {
            let d = item.data;
            return (
              (d.FinalPaid && (
                <NumberFormat
                  value={d.FinalPaid}
                  displayType={"text"}
                  thousandSeparator=" "
                  dicimalSeparator=","
                  isNumericString={false}
                />
              )) ||
              "0"
            );
          },
        },
        {
          dataField: "FinalOverdue",
          caption: "Paid",
          alignment: "right",
          dataType: "number",
          cellRender: (item) => {
            let d = item.data;
            return (
              (d.FinalOverdue && (
                <NumberFormat
                  value={d.FinalOverdue}
                  displayType={"text"}
                  thousandSeparator=" "
                  dicimalSeparator=","
                  isNumericString={false}
                />
              )) ||
              "0"
            );
          },
        },
      ],
    },
    {
      dataField: "ClaimStatus",
      caption: "Status O/C",
      alignment: "right",
      dataType: "string",
    },
    { dataField: "InsYear", caption: "Policy Year", alignment: "right", dataType: "string" },
    { dataField: "LastUpdated", caption: "Last Updated", alignment: "right", dataType: "date", format: "dd MM yyyy" },
    { dataField: "ObjectName", caption: "Name of Vessel", alignment: "left", dataType: "string" },
    {
      dataField: "DueToOwners",
      caption: "Due to owners",
      alignment: "right",
      dataType: "number",
      cellRender: (item) => {
        let d = item.data;
        return (
          (d.DueToOwners && (
            <NumberFormat
              value={d.DueToOwners}
              displayType={"text"}
              thousandSeparator=" "
              dicimalSeparator=","
              isNumericString={false}
            />
          )) ||
          "0"
        );
      },
    },
    { dataField: "ClaimDate", caption: "Date of loss", alignment: "right", dataType: "date", format: "dd MM yyyy" },
    { dataField: "Interest", caption: "Insurance", alignment: "left", dataType: "string" },
    { dataField: "ClaimInfo", caption: "Description", alignment: "left", dataType: "string" },
    { dataField: "Currency", caption: "Currency", alignment: "left", dataType: "string" },
    {
      dataField: "GrossClaim",
      caption: "Gross Claim",
      alignment: "right",
      dataType: "number",
      cellRender: (item) => {
        let d = item.data;
        return (
          (d.GrossClaim && (
            <NumberFormat
              value={d.GrossClaim}
              displayType={"text"}
              thousandSeparator=" "
              dicimalSeparator=","
              isNumericString={false}
            />
          )) ||
          "0"
        );
      },
    },
    {
      dataField: "Deductible",
      caption: "Deductible",
      alignment: "right",
      dataType: "number",
      cellRender: (item) => {
        let d = item.data;
        return (
          (d.Deductible && (
            <NumberFormat
              value={d.Deductible}
              displayType={"text"}
              thousandSeparator=" "
              dicimalSeparator=","
              isNumericString={false}
            />
          )) ||
          "0"
        );
      },
    },
    {
      dataField: "NetClaim",
      caption: "Net Reserve",
      alignment: "right",
      dataType: "number",
      cellRender: (item) => {
        let d = item.data;
        return (
          (d.NetClaim && (
            <NumberFormat
              value={d.NetClaim}
              displayType={"text"}
              thousandSeparator=" "
              dicimalSeparator=","
              isNumericString={false}
            />
          )) ||
          "0"
        );
      },
    },

    {
      dataField: "PaidClaim",
      caption: "Net Paid",
      alignment: "right",
      dataType: "number",
      cellRender: (item) => {
        let d = item.data;
        return (
          (d.PaidClaim && (
            <NumberFormat
              value={d.PaidClaim}
              displayType={"text"}
              thousandSeparator=" "
              dicimalSeparator=","
              isNumericString={false}
            />
          )) ||
          "0"
        );
      },
    },

    {
      dataField: "Outstanding",
      caption: "Outstanding",
      alignment: "right",
      dataType: "number",
      cellRender: (item) => {
        let d = item.data;
        return (
          (d.Outstanding && (
            <NumberFormat
              value={d.Outstanding}
              displayType={"text"}
              thousandSeparator=" "
              dicimalSeparator=","
              isNumericString={false}
            />
          )) ||
          "0"
        );
      },
    },

    {
      dataField: "FeeExpense",
      caption: "Expenses",
      alignment: "right",
      dataType: "number",
      cellRender: (item) => {
        let d = item.data;
        return (
          (d.FeeExpense && (
            <NumberFormat
              value={d.FeeExpense}
              displayType={"text"}
              thousandSeparator=" "
              dicimalSeparator=","
              isNumericString={false}
            />
          )) ||
          "0"
        );
      },
    },

    {
      dataField: "SurveyReport",
      caption: "Survey Report",
      alignment: "right",
      dataType: "date",
      format: "dd MM yyyy",
    },

    {
      dataField: "GeneralAccount",
      caption: "General Account",
      alignment: "right",
      dataType: "date",
      format: "dd MM yyyy",
    },
  ];

  return (
    <>
      <div className="flex">
        <div style={{ marginBottom: "20px" }}>
          <table style={{ width: "300px", borderCollapse: "separate" }}>
            <thead>
              <tr>
                <td className="bg-purple grey-box text-center">Purple</td>
                <td className="bg-blue grey-box text-center">Blue</td>
                <td className="bg-green grey-box text-center">Green</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-purple grey-box text-center">Not Paid</td>
                <td className="bg-blue grey-box text-center">Partly Paid</td>
                <td className="bg-green grey-box text-center">All Paid</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="push-right text-right" style={{ width: "50%" }}>
          {/* <Tooltip title={`Do you have more ${ENTITY_PLURAL}? Click here to add another`}>
            <Button className="bg-success" icon={<PlusCircleOutlined />} onClick={handleEdit}>
              Add new {ENTITY}
            </Button>
          </Tooltip> */}
          {AuthService.isEdgeUser() && (
            <>
              <h3>
                Select Principal:{" "}
                {principals && (
                  <Select
                    showSearch
                    //style={{ width: "100%" }}
                    //placeholder="Select Principals where this contact is Account Executive"
                    optionFilterProp="children"
                    options={principals}
                    filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(p) => {
                      setPrincipal(p);
                      LookupService.SetAppPrincipal(p);
                    }}
                    style={{ width: "60%", textAlign: "left" }}
                    defaultValue={LookupService.GetAppPrincipal()}
                  ></Select>
                )}
              </h3>
            </>
          )}

          {AuthService.isPrincipalUser() && (
            <h3>
              Principal: <span className="text-warning"> {principalName}</span>
            </h3>
          )}
        </div>
      </div>
      <div>
        {claims && (
          <CustomDataGrid
            data={claims}
            columns={columns}
            PK_FIELD={PK_FIELD}
            ENTITY={ENTITY}
            ENTITY_PLURAL={ENTITY_PLURAL}
            //editHandler={handleEdit}
            //deleteHandler={handleDelete}
            //bulkDeleteHandler={handleDelete}
            canEdit={false}
            canDelete={false}
            canSelect={false}
            canBulkDelete={false}
            onRowPrepared={rowPrepared}
          />
        )}
      </div>

      {showEditForm && (
        <Form_Claim
          form={form}
          showForm={showEditForm}
          recordToEdit={recordToEdit}
          handleCancel={handleCancel}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          ENTITY={ENTITY}
          data={{ principals }}
        />
      )}
    </>
  );
};

export default Claims;
