import React, { useState, useEffect } from "react";

import { trackPromise } from "react-promise-tracker";
import { notify } from "Services/ToastService";

import { Button, Row, Col, Image, Card, Input, Modal, Upload, Form, Space, Tooltip, Popconfirm, Checkbox } from "antd";
import { PlusCircleOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";

import CustomDataGrid from "Components/DevEx/CustomDataGrid";
import Form_Contact from "./Components/Form_Contact";

import UserService from "Services/API/UserService";
import LookupService from "Services/API/LookupService";
import { defaultUser } from "Components/Common/Images/Images";

const Contacts = (props) => {
  const ENTITY = "Contact";
  const ENTITY_PLURAL = "Contacts";
  const PK_FIELD = "ContactId";

  const [form] = Form.useForm();
  const [showEditForm, setShowEditForm] = useState(false);

  const [data, setData] = useState();
  const [recordToEdit, setRecordToEdit] = useState();

  const [principals, setPrincipals] = useState();
  useEffect(async () => {
    await handleSearch();
    setPrincipals(await trackPromise(LookupService.Principals()));
    return () => {
      //setData([]);
    };
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [recordToEdit]);

  const handleSearch = async () => {
    let { data, status, message } = await trackPromise(UserService.Contact_List());
    notify(message, status);
    setData(data);
  };
  const handleEdit = async (item) => {
    //console.log("Edit Handler", item);
    item.PictureName = null;
    setRecordToEdit(item[PK_FIELD] ? item : null);
    setShowEditForm(true);
  };

  const handleDelete = async (item) => {
    //console.log("Delete Handler", item);
    let { data, status, message } = await trackPromise(UserService.Contact_Delete(item[PK_FIELD]));
    notify(message, status);
    await handleSearch();
  };

  const handleCancel = () => {
    setShowEditForm(false);
  };

  const onFinish = async (values) => {
    //console.log(files);

    /* IF FILES NEED TO BE UPLOADED VIA FORM
    const formData = new FormData();
     files.forEach((file) => {
       formData.append("files[]", file);
     });
    */
    let record = values;
    console.log("form values", values);

    //SET SOME DEFAULT VALUES HERE
    // if (logoImage) {
    //   record.business_logo = logoImage.base64.substring(logoImage.base64.indexOf(",") + 1);
    // }
    //record.is_default = false;

    const { data, status, message } = record[PK_FIELD]
      ? await trackPromise(UserService.Contact_Update(record))
      : await trackPromise(UserService.Contact_Add(record));

    notify(message, status);
    if (status) {
      //setLogoImage(null);
      setRecordToEdit({});
      setShowEditForm(false);
      handleSearch();
    }
  };
  const onFinishFailed = async () => {
    notify("Please provide all the required input fields", false);
  };

  const columns = [
    {
      caption: "Photo",
      dataField: "ContactId",
      dataType: "string",
      alignment: "center",
      width: "90px",
      cellRender: (item) => {
        let row = item.row.data;
        return (
          <Image
            className="box-photo"
            src={`data:image/jpeg;base64,${row.Picture || defaultUser()}`}
            style={{ width: "70px" }}
          />
        );
      },
    },
    {
      caption: "Full Name",
      dataField: "FullName",
      dataType: "string",
      cellRender: (itemData) => {
        return <b>{itemData.text}</b>;
      },
      showSummary: true,
      summaryType: "count",
    },
    {
      caption: "Email",
      dataField: "Email",
      dataType: "string",
    },
    {
      caption: "Phone",
      dataField: "Phone",
      dataType: "string",
    },
    {
      caption: "Position",
      dataField: "Position",
      dataType: "string",
    },
    {
      caption: "Active",
      dataField: "Active",
      dataType: "boolean",
      alignment: "center",
      width: "80",
    },
  ];

  return (
    <>
      <div className="flex">
        <h3 style={{ marginBottom: "20px" }}> Manage {ENTITY_PLURAL}</h3>
        <h3 className="push-right text-right">
          <Tooltip title={`Do you have more ${ENTITY_PLURAL}? Click here to add another`}>
            <Button className="bg-success" icon={<PlusCircleOutlined />} onClick={handleEdit}>
              Add new {ENTITY}
            </Button>
          </Tooltip>
        </h3>
      </div>
      <CustomDataGrid
        data={data}
        columns={columns}
        ENTITY={ENTITY}
        ENTITY_PLURAL={ENTITY_PLURAL}
        editHandler={handleEdit}
        deleteHandler={handleDelete}
        canEdit={true}
        canDelete={true}
        doPaging={true}
      />
      {showEditForm && (
        <Form_Contact
          form={form}
          showForm={showEditForm}
          recordToEdit={recordToEdit}
          handleCancel={handleCancel}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          ENTITY={ENTITY}
          data={{ principals }}
        />
      )}
    </>
  );
};

export default Contacts;
