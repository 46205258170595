import { Button, Result } from "antd";
import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom";
import UserService from "Services/API/UserService";
import { notify } from "Services/ToastService";

function VerifyUser(props) {
  let { token } = useParams();
  const [verified, setVerified] = useState(false);
  useEffect(async () => {
    //alert(token);

    const { data, status, message } = await trackPromise(UserService.Verify(token));
    notify(message, status);
    if (status && data) {
      setVerified(true);
    }
  }, []);
  return (
    <>
      <Result
        status={verified ? "success" : "warning"}
        title={
          verified ? "You have successfully verified your Service Vault account" : "ٰFailed to verify your registration"
        }
        subTitle={
          verified
            ? "You can now proceed to the login process with your credentials"
            : "Please contact our support about your problem"
        }
        extra={[
          verified ? (
            <Button
              type="primary"
              onClick={() => {
                props.history.push("/login");
              }}
            >
              Log in now
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                props.history.push("/contact-support");
              }}
            >
              Contact Support
            </Button>
          ),
          <Button
            type="primary"
            className="bg-default"
            onClick={() => {
              props.history.push("/");
            }}
          >
            Go to Home Page
          </Button>,
        ]}
      />
      <h2></h2>
    </>
  );
}
export default VerifyUser;
