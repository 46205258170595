import { Col, Popover, Row, Space } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { Link } from "react-router-dom";

import user_pic from "assets/images/users/default.png";
import AuthService from "Services/AuthService";
import environment from "Environment";
const Profile = ({ layout, onLinkClicked }) => {
  const user = AuthService.getCurrentUser();

  const text = <span style={{ color: "#777" }}>{user && user.fullName}</span>;
  const content = (
    <div>
      {/* <a className="html-link" onClick={() => onLinkClicked("/app/user/profile")}>
        <div>My Profile</div>
      </a> */}
      <a className="html-link" onClick={() => onLinkClicked("/app/user/change-password")}>
        <div>Change Password</div>
      </a>
      <Link
        to="#"
        onClick={() => {
          AuthService.logout();
          window.location.href = "/session-expired";
        }}
      >
        <div style={{ color: "red" }}>Log out</div>
      </Link>
    </div>
  );
  return (
    <>
      <Popover placement="bottomRight" title={text} content={content} trigger="click">
        <Row style={{ cursor: "pointer" }}>
          {layout === "full" && (
            <Col
              className=""
              style={{
                maxWidth: "300px",
                marginTop: "15px",
                marginRight: "10px",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              Logged on as:
              <span className="text-bold ml-2" style={{ color: "#1c78bc" }}>
                {user && user.fullName}
              </span>
              <br />
              <span className="text-small flex">
                User Role:{" "}
                <span className="ml-auto" style={{ color: "#15588A" }}>
                  ({user.role})
                </span>
              </span>
            </Col>
          )}
          <Col>
            <Avatar size={38} icon={<img src={user_pic} style={{ border: "dotted 1px #aaa", padding: "1px" }} />} />
          </Col>
        </Row>
      </Popover>
    </>
  );
};

export default Profile;
