import { FormButtons } from "Components/Common/FormButtons";
import { defaultUser } from "Components/Common/Images/Images";
import ApiUploader from "Components/Common/ApiUploader";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import LookupService from "Services/API/LookupService";
import moment from "moment";

const { Modal, Form, Input, Checkbox, Select, Button, Image, Switch, DatePicker } = require("antd");
const { LoadingPanelForPopup } = require("Layout/LoadingPanels");

const Form_Claim = ({ form, showForm, recordToEdit, handleCancel, onFinish, onFinishFailed, ENTITY }) => {
  useEffect(async () => {
    //if (data && data.principals) setPrincipals(data.principals);
    if (recordToEdit && recordToEdit.Clmnum) {
      //get related data from DB here (if needed)
      form.resetFields();
    }
  }, [recordToEdit]);

  return (
    <Modal
      width={400}
      title={`Update ${ENTITY}`}
      visible={showForm}
      // onOk={handleSave}
      onCancel={handleCancel}
      footer={[<FormButtons {...{ form, handleCancel, ENTITY }} />]}
    >
      <LoadingPanelForPopup />
      <Form
        form={form}
        name={`form-${ENTITY}`}
        layout="vertical"
        initialValues={recordToEdit}
        labelCol={{}}
        wrapperCol={{}}
        autoComplete="off"
        size="middle"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="Clmnum" hidden />
        <Form.Item label="" style={{ marginBottom: 0 }}>
          <Form.Item
            name="SurveyReport"
            label="Survey Report"
            style={{ display: "inline-block", width: "calc(50% - 1px)", margin: "0 1px" }}
          >
            <DatePicker format="DD/MM/YYYY" defaultValue={moment()} />
          </Form.Item>

          <Form.Item
            name="GeneralAccount"
            label="General Account"
            style={{ display: "inline-block", width: "calc(49% - 1px)", margin: "0 1px" }}
          >
            <DatePicker format="DD/MM/YYYY" defaultValue={moment()} />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form_Claim;
