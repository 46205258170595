import { Form, Button, Card, Col, Input, Result, Row } from "antd";
import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link, useParams } from "react-router-dom";
import UserService from "Services/API/UserService";
import { notify } from "Services/ToastService";
import logo_icon from "assets/images/logo.png";

function ResetPassword(props) {
  let { id } = useParams();
  let { token } = useParams();
  const [form] = Form.useForm();
  const [verified, setVerified] = useState(false);

  const [recoveryStatus, setRecoveryStatus] = useState(false);
  const [user, setUser] = useState();

  useEffect(async () => {
    //alert(token);

    const { data, status, message } = await trackPromise(UserService.PasswordResetLinkValidation(id, token));
    notify(message, status);
    data.Password = "";
    setUser(data);
    setVerified(status);
    form.resetFields();
  }, []);

  const onFinish = async (values) => {
    //alert("form finished");
    //console.log("form", form);
    //const username = values.Username;
    const id = values.UserId;
    const newPassword = values.Password;
    const { status, message } = await trackPromise(UserService.PasswordReset(id, token, newPassword));

    notify(message, status);

    if (status) {
      setRecoveryStatus(true);
    }
  };

  const onFinishFailed = () => {
    //alert("Please fill form with");
    notify("Please fill the form with your registered username", false);
  };
  return (
    <>
      <Row gutter={[5, 5]} align="middle" justify="center">
        <Col xl={8} xs={22}>
          <div className="flex p-4" style={{ alignItems: "center" }}>
            <h1 style={{ display: "inline-block" }}> Password Recovery</h1>
            <img className="ml-auto text-right push-right" src={logo_icon} style={{ width: "300px" }} />
          </div>
        </Col>
      </Row>
      {verified && !recoveryStatus && (
        <Row gutter={[5, 5]} align="middle" justify="center">
          <Col xl={8} xs={22}>
            <Card
              className="card-box"
              actions={[
                // <Link to="/register">New User? Sign up please!</Link>,
                <Link to="/login">Already registered? Please Login!</Link>,
              ]}
            >
              <div className="box-pad text-center">
                <h1>Reset your password?</h1>
              </div>
              <div className="box-pad fill-width fill-height">
                <Form
                  form={form}
                  name="reset-password"
                  layout="vertical"
                  initialValues={user}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  size="large"
                >
                  <Form.Item name="UserId" hidden />

                  <table style={{ width: "100%", borderCollapse: "separate" }}>
                    <tr>
                      <td>Username:</td>
                      <td className="p-3">
                        <b>{user && user.Username}</b>
                      </td>
                      <td>Email:</td>
                      <td className="p-3">
                        <b>{user && user.Email}</b>
                      </td>
                    </tr>
                  </table>

                  <Form.Item label="">
                    <Form.Item
                      label="New Password"
                      name="Password"
                      rules={[{ required: true, message: "Please enter your new password!" }]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      label="Confirm New Password"
                      name="ConfirmedPassword"
                      dependencies={["Password"]}
                      hasFeedback
                      rules={[
                        { required: true, message: "Please confirm your new password!" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("Password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error("The confirmed password should match your password!"));
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Change Password
                    </Button>{" "}
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      )}
      {recoveryStatus && (
        <Result
          status={recoveryStatus ? "success" : "warning"}
          title={
            recoveryStatus ? "You have successfully reset your password" : "ٰFailed to verify your account registration"
          }
          subTitle={
            recoveryStatus
              ? "You can now proceed to the login process with your new credentials"
              : "Please contact our support about your problem"
          }
          extra={[
            recoveryStatus ? (
              <Button
                type="primary"
                onClick={() => {
                  props.history.push("/login");
                }}
              >
                Log in now
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  props.history.push("/contact-support");
                }}
              >
                <a href="mailto:">Contact Support</a>
              </Button>
            ),
            <Button
              type="primary"
              className="bg-default"
              onClick={() => {
                props.history.push("/");
              }}
            >
              Go to Home Page
            </Button>,
          ]}
        />
      )}
    </>
  );
}
export default ResetPassword;
