import React from "react";

const Admin = (props) => {
  return (
    <>
      Admin page
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
      <p>lots of contents</p>
    </>
  );
};

export default Admin;
