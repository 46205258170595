import { FormButtons } from "Components/Common/FormButtons";
import { defaultUser } from "Components/Common/Images/Images";
import ApiUploader from "Components/Common/ApiUploader";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import LookupService from "Services/API/LookupService";
import ImageSelector from "Components/Common/ImageSelector";

const { Modal, Form, Input, Checkbox, Select, Button, Image, Switch } = require("antd");
const { LoadingPanelForPopup } = require("Layout/LoadingPanels");

const Form_EdgeUser = ({ form, showForm, recordToEdit, handleCancel, onFinish, onFinishFailed, ENTITY, data }) => {
  const [unassignedPrincipals, setUnassignedPrincipals] = useState();
  const [passwordSettings, setPasswordSettings] = useState(recordToEdit && recordToEdit.UserId > 0 ? false : true);
  const [ifPwdChanged, setIfPwdChanged] = useState(false);
  useEffect(async () => {
    let ups = await trackPromise(LookupService.UnassignedPrincipals((recordToEdit && recordToEdit.UserId) || 0));
    setUnassignedPrincipals(ups);
  }, []);

  const setSelectedFilename = (filename) => {
    //setPictureName(filename);
    //recordToEdit.PictureName = filename;
    form.setFieldsValue({
      ["PictureName"]: filename,
    });
  };

  return (
    <Modal
      width={768}
      title={`Add/Edit ${ENTITY}`}
      visible={showForm}
      // onOk={handleSave}
      onCancel={handleCancel}
      footer={[<FormButtons {...{ form, handleCancel, ENTITY }} />]}
    >
      <LoadingPanelForPopup />
      <Form
        form={form}
        name={`form-${ENTITY}`}
        layout="vertical"
        initialValues={recordToEdit}
        labelCol={{}}
        wrapperCol={{}}
        autoComplete="off"
        size="middle"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="UserId" hidden />
        <Form.Item>
          <Form.Item
            name="Username"
            label="Username"
            rules={[{ required: true, message: "Please input your Username!" }]}
            hasFeedback
            style={{ display: "inline-block", width: "calc(50% - 1px)" }}
          >
            <Input placeholder="Username" maxLength={40} />
          </Form.Item>
          <Form.Item
            name="RoleName"
            label="Role"
            style={{ display: "inline-block", width: "calc(50% - 1px)", margin: "0 1px" }}
            rules={[{ required: true, message: "Please select a User role" }]}
          >
            <Select>
              <Select.Option value="Edge User">Edge User</Select.Option>
              <Select.Option value="Edge Admin">Edge Admin</Select.Option>
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item label="User Photo">
          <Form.Item name="PictureName" hidden />

          <div className="flex">
            <div>
              Existing Photo
              <br />
              <Image
                src={`data:image/jpeg;base64,${(recordToEdit && recordToEdit.Picture) || defaultUser()}`}
                style={{ width: "120px" }}
              />
            </div>
            <div className="mr-auto">
              New Photo
              <br />
              <ApiUploader fileType="picture" multiple={false} uploadLimit={20} onFileSelect={setSelectedFilename} />
            </div>
            <div className="ml-auto box bg-grey box-pad" style={{ width: "50%" }}>
              {recordToEdit && recordToEdit.UserId > 0 && (
                <Switch
                  style={{ border: "none" }}
                  checked={passwordSettings}
                  checkedChildren={<span>Don't Change</span>}
                  unCheckedChildren={<span>Change Password</span>}
                  onChange={() => setPasswordSettings(!passwordSettings)}
                />
              )}
              {passwordSettings && (
                <Form.Item label="">
                  <Form.Item
                    label="Password"
                    name="Password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                    style={{ display: "inline-block", width: "calc(50% - 1px)" }}
                    hasFeedback
                  >
                    <Input.Password maxLength={20} />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="ConfirmedPassword"
                    dependencies={["Password"]}
                    style={{ display: "inline-block", width: "calc(50% - 1px)", margin: "0 1px" }}
                    hasFeedback
                    rules={[
                      { required: true, message: "Please confirm your password!" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("Password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("The confirmed password should match your password!"));
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password maxLength={20} />
                  </Form.Item>
                </Form.Item>
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item label="Name" style={{ marginBottom: 0 }}>
          <Form.Item
            name="FirstName"
            rules={[{ required: true, message: "First name is required" }]}
            style={{ display: "inline-block", width: "calc(50% - 1px)" }}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="LastName"
            rules={[{ required: true, message: "Last name is required" }]}
            style={{ display: "inline-block", width: "calc(50% - 1px)", margin: "0 1px" }}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="Email"
          label="Email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "This is not a correct email address" },
          ]}
        >
          <Input placeholder="Email here" />
        </Form.Item>

        <Form.Item label="" style={{ marginBottom: 0 }}>
          <Form.Item name="Telephone" label="Telephone" style={{ display: "inline-block", width: "calc(50% - 1px)" }}>
            <Input placeholder="Telephone" />
          </Form.Item>
          <Form.Item
            name="Ins2000ID"
            label="Ins2000+ID"
            style={{ display: "inline-block", width: "calc(50% - 1px)", margin: "0 1px" }}
          >
            <Input placeholder="Ins2000+ID" />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item
            name="SendPasswordEmail"
            valuePropName="checked"
            style={{ display: "inline-block", width: "calc(50% - 1px)" }}
          >
            <Checkbox>Send email about account updates</Checkbox>
          </Form.Item>
          <Form.Item
            name="Active"
            valuePropName="checked"
            style={{ display: "inline-block", width: "calc(50% - 1px)", margin: "0 1px" }}
          >
            <Checkbox>Active?</Checkbox>
          </Form.Item>
        </Form.Item>
        <Form.Item name="Narrative" label="Contact Page Narrative:">
          <Input.TextArea placeholder="Contact Page Narrative" />
        </Form.Item>

        <Form.Item name="PRINCIPAL_NBR_AE" label="Account Executive for">
          <Select
            showSearch
            mode="multiple"
            //style={{ width: "100%" }}
            //placeholder="Select Principals where this contact is Account Executive"
            optionFilterProp="children"
            options={unassignedPrincipals}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          ></Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form_EdgeUser;
