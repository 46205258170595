import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "Services/AuthService";
// import { useForm, Controller } from "react-hook-form";
import { notify } from "Services/ToastService";
import { Card, Form, Checkbox, Input, Button, Row, Col, message } from "antd";
import { doWait } from "Lib/ReactHelper";
import { trackPromise } from "react-promise-tracker";
import UserService from "Services/API/UserService";
import { EmailRule } from "devextreme-react/data-grid";
import logo_icon from "assets/images/logo.png";

const { Meta } = Card;
const Login = (props) => {
  const [form] = Form.useForm();
  const [twoFaInAction, setTwoFaInAction] = useState(false);
  const [authUser, setAuthUser] = useState();
  const [twoFaMessage, setTwoFaMessage] = useState();

  useEffect(() => {
    AuthService.logout();
    return () => {
      //cleanup
    };
  }, []);

  const onFinish = async (values) => {
    const aUser = {
      username: values.username || authUser.username,
      password: values.password || authUser.password,
      twoFaCode: values.twoFaCode,
    };
    const { data, status, message } = await trackPromise(UserService.Login(aUser));

    if (status) {
      if (data === "2FA") {
        setAuthUser(aUser);
        setTwoFaMessage(message);
        setTwoFaInAction(true);
        return;
      } else {
        AuthService.loginWithJwt(data);
      }
    }
    //const { status, message } = await AuthServicte.dummyLogin(authUser);

    !status && notify(message, status);

    if (status && AuthService.isLoggedIn()) {
      props.history.push("/app/e-docs");
      window.location.href = "/app/e-docs";
    }
  };

  const onFinishFailed = () => {
    //message.error("Please fill the input form with required information");
    notify("Please fill the input form with required information", false);
  };
  return (
    <Row gutter={[5, 5]} align="middle" justify="center">
      <Col xl={8} xs={22}>
        <Card
          className="card-box"
          actions={[
            // <Link to="/register">New User? Sign up please!</Link>,
            <Link to="/forgot-password">Forgot your password?</Link>,
          ]}
        >
          <div className="">
            <div className="flex p-4" style={{ alignItems: "center" }}>
              <h1 style={{ display: "inline-block" }}>{(twoFaInAction && "2 Factor Authentication") || "Login"}</h1>
              <img className="ml-auto text-right push-right" src={logo_icon} style={{ width: "300px" }} />
            </div>
            <div className="flex p-4">
              <h4 className="">
                {(twoFaInAction && `${twoFaMessage}`) ||
                  "Please provide valid credentials in order to access ECP dashboard"}
              </h4>
            </div>
          </div>
          <div className="p-3 fill-width fill-height">
            <Form
              form={form}
              name="login"
              labelCol={{}}
              wrapperCol={{}}
              layout="vertical"
              initialValues={{ remember: true, username: "", password: "", twoFaCode: "" }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              size="large"
            >
              {!twoFaInAction && (
                <>
                  <Form.Item
                    label="Username/Email"
                    name="username"
                    rules={[{ required: true, message: "Please input your username!" }]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                    hasFeedback
                  >
                    <Input.Password maxLength={20} />
                  </Form.Item>

                  <Form.Item name="remember" valuePropName="checked" wrapperCol={{}}>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>
                </>
              )}

              {twoFaInAction && (
                <Form.Item
                  name="twoFaCode"
                  label="2 Factor Authentication OTP"
                  rules={[{ required: true, message: "Please input your password!" }]}
                  style={{ width: "50%" }}
                >
                  <Input
                    maxLength={5}
                    style={{ fontFamily: "monospace", fontSize: "16px", color: "#898989", letterSpacing: "2px" }}
                  />
                </Form.Item>
              )}

              <Form.Item wrapperCol={{}}>
                <Button type="primary" htmlType="submit">
                  {(twoFaInAction && "Verify Login") || "Login"}
                </Button>{" "}
              </Form.Item>
            </Form>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
