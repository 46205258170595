const environment = {
  NAME: "DEVELOPMENT",
  APPLICATION_NAME: "DEVELOPMENT",
  VERSION: "1.0",
  USERNAME: "admin",
  PASSWORD: "123",
  ENVIRONMENT_CLASS: "bg-primary text-light",
  API: {
    BASE_URL: "http://localhost:8085/api",
    USERNAME: "khurram",
    PASSWORD: "123",
    API_TIMEOUT: 500000,
    VERSION: "",
  },
};

if (process.env.REACT_APP_ENV === "staging") {
  environment.NAME = "STAGING";
  environment.ENVIRONMENT_CLASS = "bg-warning text-dark";
  environment.API.BASE_URL = "https://ecp-uat.uk.lockton.com/api";
  environment.API.VERSION = "";
}

if (process.env.REACT_APP_ENV === "production") {
  environment.NAME = "PRODUCTION";
  environment.ENVIRONMENT_CLASS = "bg-success";
  environment.API.BASE_URL = "https://ecp.uk.lockton.com/api";
  environment.API.VERSION = "";
}

export default environment;
