import React from "react";
import { Route } from "react-router-dom";
import AuthService from "Services/AuthService";

export const ProtectedRoute = ({ key, path, component: Component, render, ...rest }) => {
  //console.log("Protected Route");
  return (
    <Route
      key={key}
      exact={true}
      path={path}
      render={(props) => {
        if (!AuthService.isLoggedIn()) {
          // return (
          //   <Redirect
          //     to={{
          //       pathname: "/session-expired",
          //       state: { from: props.location },
          //     }}
          //   />
          // );
          //props.history.push("/session-expired");
          window.location.href = "/session-expired";
        }
        return Component ? <Component {...props} /> : render(props);
      }}
      {...rest}
    />
  );
};

export const AdminRoute = ({ key, path, component: Component, render, ...rest }) => {
  return (
    <Route
      key={key}
      path={path}
      render={(props) => {
        if (!AuthService.isAdmin()) {
          //return (
          // <Redirect
          //   to={{
          //     pathname: "/login",
          //     state: { from: props.location },
          //     key: "-1",
          //     search: "?type=restricted&role=admin",
          //   }}
          // />
          window.location.href = "/session-expired";
          //);
        }
        return Component ? <Component {...props} /> : render(props);
      }}
      {...rest}
    />
  );
};

// export async function getBase64(file) {
//   let document = "";
//   let reader = new FileReader();
//   reader.readAsDataURL(file);
//   reader.onload = function () {
//     document = reader.result;
//   };
//   reader.onerror = function (error) {
//     console.log("Error: ", error);
//   };

//   return document;
// }

export const doWait = (ms) => {
  //pass a time in milliseconds to this function
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getLookupDataSource = (data, labelField, valueField, defaultLabel, defaultValue) => {
  const result =
    data &&
    data.map((d) => {
      return { label: labelField ? d[labelField] : d, value: valueField ? d[valueField] : d };
    });
  const def = { label: defaultLabel, value: defaultValue };
  if (defaultLabel) {
    return [{ ...def }, ...result];
  } else {
    return result;
  }
};
