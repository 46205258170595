import BaseApiService from "Services/BaseApiService";

class UserService extends BaseApiService {
  static async Register(user) {
    const url = `/users/register`;
    const postData = user;
    return await super.POST(url, postData);
  }

  static async Verify(verification_token) {
    const url = `/auth/verify/${verification_token}`;
    return await super.GET(url);
  }

  static async Login(user) {
    const url = `/auth/login`;
    const postData = user;
    return await super.POST(url, postData);
  }

  static async PasswordResetRequest(username) {
    const url = `/auth/PasswordResetRequest/${username}`;
    return await super.POST(url);
  }

  //it just verifies that the clicked link for resetting password is valid or not
  static async PasswordResetLinkValidation(userId, vCode) {
    const url = `/auth/PasswordResetLinkValidation/${userId}/${vCode}`;
    return await super.GET(url);
  }
  //it actually resets the password of user
  static async PasswordReset(userId, vCode, newPassword) {
    const url = `/auth/PasswordReset/${userId}/${vCode}/${newPassword}`;
    return await super.POST(url);
  }

  static async ChangePassword(userId, oldPassword, newPassword) {
    const url = `/auth/ChangePassword`;
    const postData = { userId, oldPassword, newPassword };
    return await super.POST(url, postData);
  }

  static async Principal_List() {
    const url = `/users/Principals`;
    return await super.GET(url);
  }

  //#region EdgeUser
  static async EdgeUser_List() {
    const url = `/users/EdgeUsers`;
    return await super.GET(url);
  }

  static async EdgeUser_Add(edgeUser) {
    const url = `/users/SaveEdgeUser`;
    const postData = edgeUser;
    return await super.POST(url, postData);
  }

  static async EdgeUser_Update(edgeUser) {
    const url = `/users/SaveEdgeUser`;
    const postData = edgeUser;
    return await super.POST(url, postData);
  }

  static async EdgeUser_Delete(id) {
    const url = `/users/DeleteEdgeUser/${id}`;
    return await super.DELETE(url);
  }

  static async UnassignedPrincipal_List(userId) {
    const url = `/users/UnassignedPrincipals${userId ? "/" + userId : ""}`;
    return await super.GET(url);
  }
  //#endregion

  //#region Principal Admin
  static async PrincipalAdmin_List() {
    const url = `/users/PrincipalAdmins`;
    return await super.GET(url);
  }

  static async PrincipalAdmin_ById(userId) {
    const url = `/users/PrincipalAdmins/${userId}`;
    return await super.GET(url);
  }

  static async PrincipalAdmin_Save(pAdmin) {
    const url = `/users/SavePrincipalAdmin`;
    const postData = pAdmin;
    return await super.POST(url, postData);
  }

  static async PrincipalAdmin_Delete(id) {
    const url = `/users/DeletePrincipalAdmin/${id}`;
    return await super.DELETE(url);
  }

  static async PrincipalDocTypes_List(pid) {
    const url = `/users/PrincipalDocTypes/${pid}`;
    return await super.GET(url);
  }

  static async PrincipalVessels_List(pid) {
    const url = `/users/PrincipalVesssels/${pid}`;
    return await super.GET(url);
  }
  //#endregion

  //#region Principal User
  static async PrincipalUser_List(principal) {
    const url = `/users/PrincipalUsers/${principal || 0}`;
    return await super.GET(url);
  }

  static async PrincipalUser_ById(userId) {
    const url = `/users/PrincipalUser/${userId}`;
    return await super.GET(url);
  }
  static async PrincipalUser_Save(pUser) {
    const url = `/users/SavePrincipalUser`;
    const postData = pUser;
    return await super.POST(url, postData);
  }

  static async PrincipalUser_Delete(id) {
    const url = `/users/DeletePrincipalUser/${id}`;
    return await super.DELETE(url);
  }

  //#endregion

  //#region Contact
  static async Contact_List(principal) {
    const url = `/users/Contacts/${principal || 0}`;
    return await super.GET(url);
  }

  static async Contact_Add(contact) {
    const url = `/users/SaveContact`;
    const postData = contact;
    return await super.POST(url, postData);
  }

  static async Contact_Update(contact) {
    const url = `/users/SaveContact`;
    const postData = contact;
    return await super.POST(url, postData);
  }

  static async Contact_Delete(id) {
    const url = `/users/DeleteContact/${id}`;
    return await super.DELETE(url);
  }

  //#endregion

  //#region Account Executive
  static async AccountExecutive_List(principal) {
    const url = `/users/AccountExecutives/${principal || 0}`;
    return await super.GET(url);
  }

  //#endregion
}

export default UserService;
