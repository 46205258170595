import { FormButtons } from "Components/Common/FormButtons";
import { defaultUser } from "Components/Common/Images/Images";
import ApiUploader from "Components/Common/ApiUploader";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import LookupService from "Services/API/LookupService";

const { Modal, Form, Input, Checkbox, Select, Button, Image, Switch } = require("antd");
const { LoadingPanelForPopup } = require("Layout/LoadingPanels");

const Form_Contact = ({ form, showForm, recordToEdit, handleCancel, onFinish, onFinishFailed, ENTITY, data }) => {
  const [pictureName, setPictureName] = useState();
  useEffect(async () => {
    //if (data && data.principals) setPrincipals(data.principals);
    if (recordToEdit && recordToEdit.UserId) {
      //get related data from DB here (if needed)
    }
  }, [recordToEdit]);

  const setSelectedFilename = (filename) => {
    form.setFieldsValue({
      ["PictureName"]: filename,
    });
  };

  return (
    <Modal
      width={768}
      title={`Add/Edit ${ENTITY}`}
      visible={showForm}
      // onOk={handleSave}
      onCancel={handleCancel}
      footer={[<FormButtons {...{ form, handleCancel, ENTITY }} />]}
    >
      <LoadingPanelForPopup />
      <Form
        form={form}
        name={`form-${ENTITY}`}
        layout="vertical"
        initialValues={recordToEdit}
        labelCol={{}}
        wrapperCol={{}}
        autoComplete="off"
        size="middle"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="ContactId" hidden />

        <Form.Item>
          <Form.Item
            name="FullName"
            label="Full Name"
            rules={[{ required: true, message: "Please input your Full Name!" }]}
            hasFeedback
            style={{ display: "inline-block", width: "calc(50% - 1px)" }}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            label=" "
            name="Active"
            valuePropName="checked"
            style={{ display: "inline-block", width: "calc(45% - 1px)", marginLeft: "20px" }}
          >
            <Checkbox>Active?</Checkbox>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Contact Photo">
          <Form.Item name="PictureName" hidden />

          <div className="flex">
            <div>
              Existing Photo
              <br />
              <Image
                src={`data:image/jpeg;base64,${(recordToEdit && recordToEdit.Picture) || defaultUser()}`}
                style={{ width: "120px" }}
              />
            </div>
            <div className="mr-auto">
              New Photo
              <br />
              <ApiUploader fileType="picture" multiple={false} uploadLimit={20} onFileSelect={setSelectedFilename} />
            </div>
            <div className="ml-auto"></div>
          </div>
        </Form.Item>
        <Form.Item
          name="Email"
          label="Email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "This is not a correct email address" },
          ]}
        >
          <Input placeholder="Email here" />
        </Form.Item>
        <Form.Item name="Address" label="Address">
          <Input.TextArea placeholder="Address here" />
        </Form.Item>
        <Form.Item name="Phone" label="Phone">
          <Input placeholder="Phone here" />
        </Form.Item>

        <Form.Item name="Position" label="Position">
          <Input placeholder="Position here" />
        </Form.Item>

        <Form.Item name="PRINCIPAL_NBR" label="Principal contact for">
          <Select
            showSearch
            mode="multiple"
            //style={{ width: "100%" }}
            //placeholder="Select Principals where this contact is Account Executive"
            optionFilterProp="children"
            options={data.principals}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          ></Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form_Contact;
