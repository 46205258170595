import React, { useState, useEffect } from "react";

import { trackPromise } from "react-promise-tracker";
import { notify } from "Services/ToastService";

import {
  Button,
  Select,
  Row,
  Col,
  Image,
  Card,
  Input,
  Modal,
  Upload,
  Form,
  Space,
  Tooltip,
  Popconfirm,
  Checkbox,
} from "antd";
import { PlusCircleOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";

import CustomDataGrid from "Components/DevEx/CustomDataGrid";

import UserService from "Services/API/UserService";
import LookupService from "Services/API/LookupService";
import Form_PrincipalAdmin from "./Components/Form_PrincipalAdmin";
import moment from "moment";
import AuthService from "Services/AuthService";

const PrincipalUsers = (props) => {
  const ENTITY = "Principal User";
  const ENTITY_PLURAL = "Principal Users";
  const PK_FIELD = "UserId";

  const [form] = Form.useForm();
  const [showEditForm, setShowEditForm] = useState(false);

  const [data, setData] = useState();
  const [recordToEdit, setRecordToEdit] = useState();

  const [principals, setPrincipals] = useState();
  const [principal, setPrincipal] = useState();
  const [principalName, setPrincipalName] = useState();

  moment.tz.setDefault("UTC");
  useEffect(async () => {
    let pData = await trackPromise(LookupService.Principals());
    pData = pData || [];
    let allP = { label: "---", value: 0 };
    const p = [{ ...allP }, ...pData];

    setPrincipals(p);

    let pNum = AuthService.PrincipalNbr();
    if (pNum !== 0) {
      let pName = p.find((x) => x.value === parseInt(pNum)).label;
      setPrincipal(pNum);
      setPrincipalName(pName);
    }
  }, []);

  useEffect(async () => {
    if (principal) {
      await handleSearch(principal);
    } else if (AuthService.isEdgeUser()) {
      await handleSearch(principal || 0);
    }
  }, [principal]);

  useEffect(() => {
    form.resetFields();
  }, [recordToEdit]);

  const handleSearch = async (p) => {
    console.log("selected principal", p);

    if (AuthService.isPrincipalUser() && p === 0) return;

    if (p === 0) {
      //for edgeuser
      p = LookupService.GetAppPrincipal();
    }
    let { data, status, message } = await trackPromise(UserService.PrincipalUser_List(p || 0));
    notify(message, status);
    setData(data);
  };

  const handleEdit = async (item) => {
    //console.log("Edit Handler", item);

    item.PublishedFrom = item.PublishedFrom ? moment(item.PublishedFrom) : moment("2018-01-01");
    item.PublishedTo = item.PublishedTo ? moment(item.PublishedTo) : moment("2021-12-31");
    item.Role = "Principal User";
    item.SendPasswordEmail = true;
    item.PrincipalNbr = item.PrincipalNbr || principal;
    item.Password = "";
    setRecordToEdit(item);
    setShowEditForm(true);
  };

  const handleDelete = async (item) => {
    //console.log("Delete Handler", item);
    let { data, status, message } = await trackPromise(UserService.PrincipalUser_Delete(item[PK_FIELD]));
    notify(message, status);
    await handleSearch();
  };

  const handleCancel = () => {
    setShowEditForm(false);
  };

  const onFinish = async (values) => {
    //console.log(files);

    /* IF FILES NEED TO BE UPLOADED VIA FORM
    const formData = new FormData();
     files.forEach((file) => {
       formData.append("files[]", file);
     });
    */
    let record = values;
    console.log("form values", values);

    //SET SOME DEFAULT VALUES HERE
    // if (logoImage) {
    //   record.business_logo = logoImage.base64.substring(logoImage.base64.indexOf(",") + 1);
    // }
    //record.is_default = false;
    record.PublishedFrom = moment(record.PublishedFrom).parseZone().format("YYYY-MM-DD");
    record.PublishedTo = moment(record.PublishedTo).parseZone().format("YYYY-MM-DD");

    const { data, status, message } = record[PK_FIELD]
      ? await trackPromise(UserService.PrincipalUser_Save(record))
      : await trackPromise(UserService.PrincipalUser_Save(record));

    notify(message, status);
    if (status) {
      //setLogoImage(null);
      setRecordToEdit({});
      setShowEditForm(false);
      handleSearch(record.PrincipalNbr);
    }
  };
  const onFinishFailed = async () => {
    notify("Please provide all the required input fields", false);
  };

  const columns = [
    {
      caption: "Principal",
      dataField: "PrincipalName",
      dataType: "string",
      showSummary: true,
      summaryType: "count",
    },
    {
      caption: "Username",
      dataField: "Username",
      dataType: "string",
      cellRender: (itemData) => {
        return <b>{itemData.text}</b>;
      },
    },
    {
      caption: "Name",
      dataField: "FirstName",
      dataType: "string",
      cellRender: (item) => {
        let row = item.row.data;

        return `${row.UserId > 0 ? row.LastName + ", " + row.FirstName : ""} `;
      },
    },
    {
      caption: "Email",
      dataField: "Email",
      dataType: "string",
    },
    {
      caption: "External",
      dataField: "Edocs",
      dataType: "boolean",
      alignment: "center",
      width: "100px",
    },
    {
      caption: "Claims",
      dataField: "Claims",
      dataType: "boolean",
      alignment: "center",
      alignment: "center",
      width: "100px",
    },
    {
      caption: "Dialogue",
      dataField: "Ddocs",
      dataType: "boolean",
      alignment: "center",
      width: "100px",
    },
    {
      caption: "Mortgagees",
      dataField: "Mdocs",
      dataType: "boolean",
      alignment: "center",
      width: "100px",
    },
    {
      caption: "Active",
      dataField: "Active",
      dataType: "boolean",
      alignment: "center",
      width: "100px",
    },
  ];

  return (
    <>
      <div className="flex">
        <h3 style={{ marginBottom: "20px" }}> Manage {ENTITY_PLURAL}</h3>
        <div className="push-right text-right" style={{ width: "50%" }}>
          {AuthService.isEdgeUser() && (
            <>
              <h3>
                Select Principal:{" "}
                <Select
                  showSearch
                  //style={{ width: "100%" }}
                  //placeholder="Select Principals where this contact is Account Executive"
                  optionFilterProp="children"
                  options={principals}
                  filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(p) => {
                    setPrincipal(p);
                    LookupService.SetAppPrincipal(p);
                  }}
                  defaultValue={LookupService.GetAppPrincipal()}
                  style={{ width: "60%", textAlign: "left" }}
                ></Select>
              </h3>
            </>
          )}

          {AuthService.isPrincipalUser() && (
            <h3>
              Principal: <span className="text-warning"> {principalName}</span>
            </h3>
          )}

          <br />
          <h3 className="push-right text-right">
            <Tooltip title={`In order to add new ${ENTITY_PLURAL}, a principal must be selected first.`}>
              <Button
                className="bg-success"
                icon={<PlusCircleOutlined />}
                onClick={handleEdit}
                disabled={!principal || principal === 0}
              >
                Add new {ENTITY}
              </Button>
            </Tooltip>
          </h3>
        </div>
      </div>

      <CustomDataGrid
        data={data}
        columns={columns}
        ENTITY={ENTITY}
        ENTITY_PLURAL={ENTITY_PLURAL}
        editHandler={handleEdit}
        deleteHandler={handleDelete}
        canEdit={true}
        canDelete={true}
      />

      {showEditForm && (
        <Form_PrincipalAdmin
          form={form}
          showForm={showEditForm}
          recordToEdit={recordToEdit}
          handleCancel={handleCancel}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          ENTITY={ENTITY}
          //data={{ unassignedPrincipals }}
        />
      )}
    </>
  );
};

export default PrincipalUsers;
