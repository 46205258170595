import React, { useState, useEffect } from "react";

import { trackPromise } from "react-promise-tracker";
import { notify } from "Services/ToastService";

import {
  Button,
  Select,
  Row,
  Col,
  Image,
  Card,
  Input,
  Modal,
  Upload,
  Form,
  Space,
  Tooltip,
  Popconfirm,
  Checkbox,
  Divider,
} from "antd";
import { PlusCircleOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";

import UserService from "Services/API/UserService";
import LookupService from "Services/API/LookupService";
import { defaultUser } from "Components/Common/Images/Images";
import { InfoList } from "Components/Common/InfoList";
import AuthService from "Services/AuthService";

const Contacts = (props) => {
  const ENTITY = "Contact";
  const ENTITY_PLURAL = "Contacts";
  const PK_FIELD = "ContactId";

  const [form] = Form.useForm();
  const [showEditForm, setShowEditForm] = useState(false);

  const [data, setData] = useState();
  const [recordToEdit, setRecordToEdit] = useState();

  const [principals, setPrincipals] = useState();
  const [principal, setPrincipal] = useState();
  const [principalName, setPrincipalName] = useState();
  const [executives, setExecutives] = useState();
  const [contacts, setContacts] = useState();

  useEffect(async () => {
    let pData = await trackPromise(LookupService.Principals());
    let allP = { label: "---", value: 0 };
    const p = [{ ...allP }, ...pData];
    setPrincipals(p);

    let pNum = AuthService.PrincipalNbr();
    if (pNum !== 0) {
      let pName = p.find((x) => x.value === parseInt(pNum)).label;
      setPrincipal(pNum);
      setPrincipalName(pName);
    }
  }, []);

  useEffect(async () => {
    await handleSearch();
  }, [principal]);

  const handleSearch = async () => {
    console.log("selected principal", principal);

    if (AuthService.isPrincipalUser() && principal === 0) return;

    let p = principal;
    //for edgeUser
    if (p !== 0) {
      p = LookupService.GetAppPrincipal();
    }

    if (p > 0) {
      const { data: pe } = await trackPromise(UserService.AccountExecutive_List(p));
      const { data: pc } = await trackPromise(UserService.Contact_List(p));

      setContacts(pc);
      setExecutives(pe);
    }
  };

  return (
    <>
      <div className="flex">
        <h3 style={{ marginBottom: "20px" }}> Edge Norway {ENTITY_PLURAL}</h3>
        <div className="push-right text-right" style={{ width: "50%" }}>
          {AuthService.isEdgeUser() && (
            <>
              <h3>
                Select Principal:{" "}
                <Select
                  showSearch
                  //style={{ width: "100%" }}
                  //placeholder="Select Principals where this contact is Account Executive"
                  optionFilterProp="children"
                  options={principals}
                  filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(p) => {
                    setPrincipal(p);
                    LookupService.SetAppPrincipal(p);
                  }}
                  defaultValue={LookupService.GetAppPrincipal()}
                  style={{ width: "60%" }}
                ></Select>
              </h3>
            </>
          )}
          {AuthService.isPrincipalUser() && (
            <h3>
              Principal: <span className="text-warning"> {principalName}</span>
            </h3>
          )}
        </div>
      </div>
      <div>
        <h2>Account Executive</h2>
        <Row gutter={24} className="fill-height">
          {executives &&
            executives.map((d, i) => {
              return (
                <Col xl={8} xs={24} key={`ae_${i}`} className="mb-2">
                  <Card className="info-card">
                    <div className="flex">
                      <div className="mr-2">
                        <p className="text-heading">
                          {d.FirstName} {d.LastName}
                        </p>
                        <InfoList
                          obj={{
                            Email: d.Email,
                            Phone: d.Telephone,
                          }}
                        />
                      </div>
                      <div className="ml-auto">
                        <Space align="end" direction="vertical">
                          <Image
                            className="box-photo"
                            src={`data:image/jpeg;base64,${d.Picture || defaultUser()}`}
                            style={{ width: "100px" }}
                          />
                        </Space>
                      </div>
                    </div>
                    <div className="text-bold">
                      <Divider />
                      {d.Narrative}
                      {!d.Narrative && `In case of emergency, please contact your H & M claims leader / P & I Club.`}
                    </div>
                    <div className="box-bottom-right"></div>
                  </Card>
                </Col>
              );
            })}
        </Row>
        <Row style={{ borderBottom: "dotted 2px #ddd" }} className="mb-3 pb-3">
          <Col></Col>
        </Row>
        {contacts && contacts.length > 0 && (
          <>
            <h2>Contacts</h2>
            <Row gutter={24} className="fill-height">
              {contacts.map((d, i) => {
                return (
                  <Col xl={8} xs={24} key={`cn_${i}`} className="mb-2">
                    <Card className="info-card">
                      <div className="flex">
                        <div className="mr-2">
                          <p className="text-heading">{d.FullName}</p>
                          <InfoList
                            obj={{
                              Email: d.Email,
                              Address: d.Address,
                              Position: d.Position,
                              Phone: d.Phone,
                            }}
                          />
                        </div>
                        <div className="ml-auto">
                          <Space align="end" direction="vertical">
                            <Image
                              className="box-photo"
                              src={`data:image/jpeg;base64,${d.Picture || defaultUser()}`}
                              style={{ width: "100px" }}
                            />
                          </Space>
                        </div>
                      </div>
                      <div className=""></div>
                      <div className="box-bottom-right"></div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Contacts;
