import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";

import PublicHeader from "./PublicHeader";
import Text from "antd/lib/typography/Text";
import { Button, Layout, Menu, Table, Switch, Space, Row, Col, Image, Card, Input, message, Typography } from "antd";

import environment from "Environment";
import { LoadingPanel } from "./LoadingPanels";
import Login from "Pages/Public/Login";
const { Header, Content, Footer } = Layout;

const PublicLayout = (props) => {
  const [toggle, setToggle] = useState("");
  const onMenuClick = (name) => setToggle(toggle === name ? "" : name);

  useEffect(() => {
    //AuthService.logout();
    return () => {
      //cleanup
    };
  }, []);

  const handleTopMenuChanged = (nav) => {
    console.log("top menue changed");
  };

  return (
    <Layout>
      {/* <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <PublicHeader layout={props.layout} history={props.history} onMenuChanged={handleTopMenuChanged} />
      </Header> */}
      <LoadingPanel />
      <Content style={{ padding: "14px", minHeight: "360px", marginTop: "64px" }}>
        <div className="site-main-public">
          {/*
           CONTENTS GOES HERE
          <Row align="top" style={{ marginTop: "15px" }}>
            <Col span={24}></Col>
          </Row>
           */}

          {props.routes.map((route, i) => (
            <Route
              key={`proute-${i}`}
              exact={true}
              path={`/${route.url}`}
              render={(props) => <route.component {...props} />}
            />
          ))}
          <Route exact={true} path="/" component={Login} />
          {/* <Route path='/' render={() => <Redirect to={{ pathname: "/error-404" }} />} /> */}
        </div>
        <Footer style={{ textAlign: "center", position: "relative", marginTop: "50px", bottom: "0" }}>
          <Text type="secondary">
            <small>
              {" "}
              EDGE ECP © 2021 <b>({environment.NAME})</b>
            </small>
          </Text>
        </Footer>
      </Content>
    </Layout>
  );
};
export default PublicLayout;
