export function clearLocalStorage() {
  window.localStorage.clear();
}

export function clearGridsCache(arrGrids) {
  if (arrGrids && arrGrids.length > 0) {
    arrGrids.forEach((key) => {
      localStorage.removeItem(key);
    });
  } else {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("dg")) {
        localStorage.removeItem(key);
      }
    });
  }
}

export function clearFiltersCache(arrFilters) {
  if (arrFilters && arrFilters.length > 0) {
    arrFilters.forEach((key) => {
      localStorage.removeItem(key);
    });
  } else {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("filters_")) {
        localStorage.removeItem(key);
      }
    });
  }
}

export function clearReportFilters(gridName) {
  let grid = JSON.parse(window.localStorage.getItem(gridName));
  if (grid && grid.columns.length > 0) {
    grid.columns.forEach((c, index) => {
      delete c.filterValue;
      delete c.filterValues;
    });

    window.localStorage.setItem(gridName, JSON.stringify(grid));
  }
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function getLocalStorage(key) {
  return localStorage.getItem(key);
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key);
}
