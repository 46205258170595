import React from "react";

import { Card, Button, Result } from "antd";

const { Meta } = Card;
const SessionExpired = (props) => {
  return (
    <div className="fill-width fill-height box-pad">
      <Result
        status="403"
        title="Session Closed"
        subTitle="You have been signed out of your current session. If you wish to access portal, please login again."
        extra={[
          <Button
            type="primary"
            onClick={() => {
              props.history.push("/login");
              //window.location.href = "/login";
            }}
          >
            Log in Again
          </Button>,
          <Button
            type="primary"
            className="bg-default"
            onClick={() => {
              props.history.push("/");
              // window.location.href = "/";
            }}
          >
            Go to Home Page
          </Button>,
        ]}
      />
    </div>
  );
};

export default SessionExpired;
