/// PUBLIC
//import LandingPage from "./Pages/Public/Landing";
//import Registration from "./Pages/Public/Registration";
import Login from "./Pages/Public/Login";
import ForgotPassword from "./Pages/Public/ForgotPassword";
import VerifyUser from "./Pages/Public/VerifyUser";
import SessionExpired from "./Pages/Public/SessionExpired";
// import LockScreen from "./Pages/Public/LockScreen";
import Error400 from "./Pages/Public/Error400";
import Error403 from "./Pages/Public/Error403";
import Error404 from "./Pages/Public/Error404";
import Error500 from "./Pages/Public/Error500";
import Error503 from "./Pages/Public/Error503";
import ContactSupport from "./Pages/Public/ContactSupport";

// /// E-Docs
import EDocs from "./Pages/App/EDocs/EDocs";

// /// Claims
import Claims from "./Pages/App/Claims/Claims";

// /// Dialogues
import Dialogues from "./Pages/App/Documents/Dialogue";

// /// External
import External from "./Pages/App/Documents/External";

// /// Mortgagees
import Mortgagees from "./Pages/App/Documents/Mortgagee";

// /// Contacts
import Contacts from "./Pages/App/Contacts/Contacts";

// /// Admin
import Admin from "./Pages/App/Admin/Admin";
import EdgeUsers from "./Pages/App/Admin/EdgeUsers";
import PrincipalAdmins from "./Pages/App/Admin/PrincipalAdmins";
import ManageContacts from "./Pages/App/Admin/Contacts";
import AccountExecutives from "./Pages/App/Admin/AccountExecutives";

// /// USER ACCOUNT
// import UserProfile from "./Pages/App/User/UserProfile";
import ChangePassword from "./Pages/App/User/ChangePassword";
import PrincipalUsers from "Pages/App/Admin/PrincipalUsers";
import ResetPassword from "Pages/Public/ResetPassword";
// import PrincipalUsers from "Pages/App/Admin/PrincipalUsers";
// import ResetPassword from "Pages/Public/ResetPassword";

export const routes = [
  /// E-Docs
  { url: "app/e-docs", component: EDocs },
  /// External
  { url: "app/external", component: External },
  /// Claims
  { url: "app/claims", component: Claims },
  /// Dialogue
  { url: "app/dialogue", component: Dialogues },
  /// Mortgagees
  { url: "app/mortgagees", component: Mortgagees },
  /// Mortgagees
  { url: "app/contacts", component: Contacts },
  /// Admin
  { url: "app/admin", component: Admin, roles: ["Edge Admin", "Principal Admin"] },
  { url: "app/admin/edge-users", component: EdgeUsers, roles: ["Edge Admin"] },
  { url: "app/admin/principal-admins", component: PrincipalAdmins, roles: ["Edge Admin"] },
  { url: "app/admin/principal-users", component: PrincipalUsers, roles: ["Edge Admin", "Principal Admin"] },
  { url: "app/admin/contacts", component: ManageContacts, roles: ["Edge Admin"] },
  { url: "app/admin/account-executives", component: AccountExecutives, roles: ["Edge Admin"] },

  /// USER Account
  { url: "app/user/change-password", component: ChangePassword },

  /// GENERAL PROTECTED PAGES for
  { url: "app/session-expired", component: SessionExpired },
  { url: "app/error-400", component: Error400 },
  { url: "app/error-403", component: Error403 },
  { url: "app/error-404", component: Error404 },
  { url: "app/error-500", component: Error500 },
  { url: "app/error-503", component: Error503 },

  // PUBLIC ROUTES
  { url: "login", auth: "public", component: Login },
  // { url: "register", auth: "public", component: Registration },
  // { url: "lock", auth: "public", component: LockScreen },
  { url: "forgot-password", auth: "public", component: ForgotPassword },
  { url: "session-expired", auth: "public", component: SessionExpired },
  { url: "error-400", auth: "public", component: Error400 },
  { url: "error-403", auth: "public", component: Error403 },
  { url: "error-404", auth: "public", component: Error404 },
  { url: "error-500", auth: "public", component: Error500 },
  { url: "error-503", auth: "public", component: Error503 },
  { url: "reset-password/:id/:token", auth: "public", component: ResetPassword },
  { url: "verify-user/:token", auth: "public", component: VerifyUser },
  { url: "contact-support", auth: "public", component: ContactSupport },
  //{ url: "app/", auth: "public", component: LandingPage },
];
