import { QuestionCircleFilled } from "@ant-design/icons";
import { FormButtons } from "Components/Common/FormButtons";
import { defaultUser } from "Components/Common/Images/Images";
import { format } from "date-fns";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import LookupService from "Services/API/LookupService";

const {
  Modal,
  Form,
  Input,
  Checkbox,
  Select,
  Button,
  Image,
  Switch,
  Row,
  Col,
  RangePicker,
  DatePicker,
  Space,
  Divider,
  Tooltip,
} = require("antd");
const { LoadingPanelForPopup } = require("Layout/LoadingPanels");

const Form_PrincipalAdmin = ({
  form,
  showForm,
  recordToEdit,
  handleCancel,
  onFinish,
  onFinishFailed,
  ENTITY,
  data,
}) => {
  const [docTypes, setDocTypes] = useState();
  const [vessels, setVessels] = useState();
  const [passwordSettings, setPasswordSettings] = useState(recordToEdit && recordToEdit.UserId > 0 ? false : true);
  const [pictureName, setPictureName] = useState();

  const [checkedAllVessels, setCheckedAllVessels] = useState(false);
  const [checkedAllDocTypes, setCheckedAllDocTypes] = useState(false);

  moment.tz.setDefault("UTC");

  useEffect(async () => {
    //if (data && data.unassignedPrincipals) setUnassignedPrincipals(data.unassignedPrincipals);
    if (recordToEdit && recordToEdit.PrincipalNbr) {
      let docTypes = await trackPromise(LookupService.PrincipalDocTypes(recordToEdit.PrincipalNbr));
      let vessels = await trackPromise(LookupService.PrincipalVessels(recordToEdit.PrincipalNbr));
      setDocTypes(docTypes);
      setVessels(vessels);
      recordToEdit.DateRange = [recordToEdit.PublishedFrom, recordToEdit.PublishedTo];
    }
  }, [recordToEdit]);

  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  const onCheckVessel = (value) => (e) => {
    // let alist = recordToEdit.AllowedVessels;
    // recordToEdit.AllowedVessels = alist.includes(value) ? alist.filter((x) => x !== value) : [...alist, value];
    // //form.resetFields();
  };
  return (
    <Modal
      width={768}
      title={`Add/Edit ${ENTITY}`}
      visible={showForm}
      // onOk={handleSave}
      onCancel={handleCancel}
      footer={[<FormButtons {...{ form, handleCancel, ENTITY }} />]}
    >
      <LoadingPanelForPopup />
      <Form
        form={form}
        name={`form-${ENTITY}`}
        layout="vertical"
        initialValues={recordToEdit}
        labelCol={{}}
        wrapperCol={{}}
        autoComplete="off"
        size="middle"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="UserId" hidden />
        <Form.Item name="PrincipalNbr" hidden />
        <Form.Item name="Role" hidden />
        <Form.Item>
          <Form.Item
            name="Username"
            label="Username"
            rules={[{ required: true, message: "Please input your Username!" }]}
            hasFeedback
            className="two-row-item"
          >
            <Input placeholder="Username 11" disabled={recordToEdit.UserId > 0} />
          </Form.Item>
          <Form.Item label="Role" className="ml-2 two-row-item">
            <b>{form.getFieldValue("Role")}</b>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Login Credentials">
          <div className="ml-auto box bg-grey box-pad mb-4">
            {recordToEdit && recordToEdit.UserId > 0 && (
              <Switch
                style={{ border: "none" }}
                checked={passwordSettings}
                checkedChildren={<span>Don't Change</span>}
                unCheckedChildren={<span>Change Password</span>}
                onChange={() => setPasswordSettings(!passwordSettings)}
              />
            )}
            {passwordSettings && (
              <div className="mt-4">
                <Form.Item
                  label="Password"
                  name="Password"
                  rules={[{ required: true, message: "Please input your password!" }]}
                  className="two-row-item"
                  hasFeedback
                >
                  <Input.Password maxLength={20} />
                </Form.Item>

                <Form.Item
                  label="Confirm Password"
                  name="ConfirmedPassword"
                  dependencies={["Password"]}
                  className="two-row-item"
                  hasFeedback
                  rules={[
                    { required: true, message: "Please confirm your password!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("Password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("The confirmed password should match your password!"));
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password maxLength={20} />
                </Form.Item>
              </div>
            )}
          </div>
        </Form.Item>
        <Form.Item label="Name" style={{ marginBottom: 0 }}>
          <Form.Item
            name="FirstName"
            rules={[{ required: true, message: "First name is required" }]}
            className="two-row-item"
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="LastName"
            rules={[{ required: true, message: "Last name is required" }]}
            className="two-row-item"
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Form.Item
            name="Email"
            label="Email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "This is not a correct email address" },
            ]}
            className="two-row-item"
          >
            <Input placeholder="Email here" />
          </Form.Item>
          <Form.Item name="Telephone" label="Telephone" className="two-row-item">
            <Input placeholder="Telephone" />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item name="SendPasswordEmail" valuePropName="checked" className="two-row-item">
            <Checkbox>Send email about account updates</Checkbox>
          </Form.Item>
          <Form.Item name="Active" valuePropName="checked" className="two-row-item">
            <Checkbox>Active?</Checkbox>
          </Form.Item>
        </Form.Item>

        {/* <Form.Item name="PRINCIPAL_NBR_AE" label="Account Executive for">
          <Select
            showSearch
            mode="multiple"
            //style={{ width: "100%" }}
            //placeholder="Select Principals where this contact is Account Executive"
            optionFilterProp="children"
            options={unassignedPrincipals}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          ></Select>
        </Form.Item> */}
        <div className="box-pad bg-grey">
          <h4>
            Set user access{" "}
            <Tooltip title="User will only have access to the selected modules">
              <QuestionCircleFilled className="text-larger text-warning" />
            </Tooltip>
          </h4>

          <Row>
            <Col span={4}>
              <Form.Item name="Claims" valuePropName="checked">
                <Checkbox style={{ lineHeight: "32px" }}>Claims</Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="Ddocs" valuePropName="checked">
                <Checkbox style={{ lineHeight: "32px" }}>Dialogues</Checkbox>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item name="Edocs" valuePropName="checked">
                <Checkbox style={{ lineHeight: "32px" }}>External</Checkbox>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item name="Mdocs" valuePropName="checked">
                <Checkbox style={{ lineHeight: "32px" }}>Mortgagees</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <div className="flex">
            <div className="box box-pad bg-white" style={{ width: "50%" }}>
              <h3 className="text-success">Permissions for E-Docs</h3>
              <div style={{ width: "100%" }}>
                <b className="text-primary">
                  Document Type(s):{" "}
                  <Tooltip title="Not selecting any document type means: this user will have access to all the the document types.">
                    <QuestionCircleFilled className="text-larger text-warning" />
                  </Tooltip>
                </b>
                <br />
                <Form.Item name="AllowedDocTypes" valuePropName="value">
                  <Checkbox.Group options={docTypes} className="ant-checkbox-group-vertical"></Checkbox.Group>
                </Form.Item>
              </div>
              <Divider />
              <Form.Item label="Duration" style={{ marginBottom: 0 }}>
                <Form.Item name="PublishedFrom" label="Published from" className="two-row-item">
                  <DatePicker format="DD/MM/YYYY" onChange={onChange} defaultValue={moment("2018-01-01")} />
                </Form.Item>

                <Form.Item name="PublishedTo" label="To" className="two-row-item">
                  <DatePicker format="DD/MM/YYYY" onChange={onChange} defaultValue={moment("2021-12-31")} />
                </Form.Item>
              </Form.Item>
            </div>
            <div className="ml-5 box box-pad bg-white" style={{ width: "50%" }}>
              <h3 className="text-success">Permissions for E-Docs and External</h3>
              <b className="text-primary">
                Vessel(s):{" "}
                <Tooltip title="Not selecting any vessel means: this user will have access to all the vessels.">
                  <QuestionCircleFilled className="text-larger text-warning" />
                </Tooltip>
              </b>
              <br />
              <Checkbox
                title="Select All"
                defaultChecked={false}
                checked={checkedAllVessels}
                onChange={(e) => {
                  let checked = e.target.checked;
                  setCheckedAllVessels(checked);
                  let aVessels = checked ? vessels && vessels.map((v) => v.value) : [];

                  //recordToEdit.AllowedVessels = aVessels;
                  form.setFieldsValue({
                    ["AllowedVessels"]: aVessels,
                  });
                  //form.resetFields();
                }}
              />{" "}
              <b>Select All Vessels</b>{" "}
              <div className="bg-blue m-2 p-3" style={{ height: "300px", overflowY: "scroll" }}>
                <Form.Item name="AllowedVessels" valuePropName="value" shouldUpdate>
                  <Checkbox.Group
                    options={vessels}
                    value={recordToEdit.AllowedVessels}
                    className="ant-checkbox-group-vertical"
                    onChange={(e) => {
                      setCheckedAllVessels(false);
                      //onCheckVessel(e);
                    }}
                  >
                    {/* {vessels &&
                      vessels.map((v, i) => {
                        return (
                          <Col key={`clVessel${i}`}>
                            
                            <Checkbox value={v.value} checked={recordToEdit.AllowedVessels.includes(v.value)}>
                              {v.label}
                            </Checkbox>
                          </Col>
                        );
                      })} */}
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default Form_PrincipalAdmin;
