import { compareAsc, compareDesc, subDays, toDate } from "date-fns";
import jwtDecode from "jwt-decode";
import UserService from "./API/UserService";
import { ApiResponse } from "./BaseApiService";

const sign = require("jwt-encode");

export async function dummyLogin(authUser) {
  logout();
  let SECRET = "SomeSecretKey";
  //authUser.password = "**********";
  authUser.role = "admin";

  let status = false;
  let message = "No response from server";
  let localdev = authUser.username === "123123123";
  if (!localdev && (authUser.username !== "danny@servicevault.com" || authUser.password !== "dannymay82")) {
    status = false;
    message = "Login Failed. Please try with correct credentials";
  } else {
    const jwt_token = sign(authUser, SECRET); // decode your token here
    if (jwt_token) {
      loginWithJwt(jwt_token);
      status = true;
      message = "Successfully logged in";
    }
  }
  return ApiResponse({ data: null, status, message });
}

export function loginWithJwt(token) {
  setToken(token);
  //setCurrentUser(user);
  //http.SetTokenInAuthHeader(jwt);
}
const tokenKey = "token";
const userKey = "user";

export function setToken(token) {
  localStorage.setItem(tokenKey, JSON.stringify(token));
}
export function getToken() {
  return JSON.parse(localStorage.getItem(tokenKey));
}

export function getAuthToken() {
  let token_data = JSON.parse(localStorage.getItem(tokenKey));
  if (token_data) return token_data;
}
export function setCurrentUser(user) {
  localStorage.setItem(userKey, JSON.stringify(user));
}
export function getCurrentUser() {
  let token = JSON.parse(localStorage.getItem(tokenKey));
  if (token) return jwtDecode(token);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(userKey);
  //http.RemoveTokenFromAuthHeader();
}

export function isLoggedIn() {
  try {
    //const token = getToken();
    const user = getCurrentUser();
    let result = false;
    // console.log(token, user);
    if (user && user.role) {
      if ([user.expiresAt, new Date()].sort(compareDesc)[0] === user.expiresAt) {
        //if (compareAsc(new Date(user.expiresAt), new Date()) === 1) {
        //if token Expiry time is greater than current datetime
        result = true;
      } else {
        result = false;
      }
    } else {
      result = false;
    }

    return result;
  } catch (ex) {
    return false;
  }
}

export function getUserRole() {
  try {
    //const token = getToken();
    const user = getCurrentUser();
    if (user && user.role) return user.role;
  } catch (ex) {
    return "";
  }
}

export function isPrincipalUser() {
  let role = getUserRole();
  if (role && (role === "Principal Admin" || role === "Principal User")) {
    return true;
  }
}

export function isEdgeUser() {
  let role = getUserRole();
  if (role && (role === "Edge Admin" || role === "Edge User")) {
    return true;
  }
}

export function PrincipalNbr() {
  let role = getUserRole();
  if (role && (role === "Principal Admin" || role === "Principal User")) {
    const user = getCurrentUser();
    return user.PrincipalNbr;
  }

  return 0;
}

export async function getPrincipalUserModules() {
  const user = getCurrentUser();

  let modules = [];

  if (user && user.role && (user.role === "Principal Admin" || user.role === "Principal User")) {
    const { data, status } =
      user.role === "Principal Admin"
        ? await UserService.PrincipalAdmin_ById(user.id)
        : await UserService.PrincipalUser_ById(user.id);

    if (status && data.length > 0) {
      let u = data[0];
      if (u.Edocs) modules.push("External");
      if (u.Claims) modules.push("Claims");
      if (u.Mdocs) modules.push("Mortgagees");
      if (u.Ddocs) modules.push("Dialogues");
    }
  } else {
    //for edge users
    modules.push("External");
    modules.push("Claims");
    modules.push("Mortgagees");
    modules.push("Dialogues");
  }
  return modules;
}

export default {
  dummyLogin,
  loginWithJwt,
  logout,
  setToken,
  getToken,
  getAuthToken,
  setCurrentUser,
  getCurrentUser,
  isLoggedIn,
  getUserRole,
  isPrincipalUser,
  isEdgeUser,
  PrincipalNbr,
  getPrincipalUserModules,
};
