import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "Services/AuthService";
// import { useForm, Controller } from "react-hook-form";
import { notify } from "Services/ToastService";
import { Card, Form, Input, Button, Row, Col, Result, Steps } from "antd";
import { trackPromise } from "react-promise-tracker";
import UserService from "Services/API/UserService";

const { Meta } = Card;
const ChangePassword = (props) => {
  const [user, setUser] = useState();
  const [form] = Form.useForm();
  const [changed, setChanged] = useState(false);

  useEffect(async () => {
    setUser(AuthService.getCurrentUser());
  }, []);

  const onFinish = async (values) => {
    //alert("form finished");
    //console.log("form", form);
    //const username = values.Username;
    const userId = parseInt(user.id);
    const oldPassword = values.oldPassword;
    const newPassword = values.newPassword;
    const { status, message } = await trackPromise(UserService.ChangePassword(userId, oldPassword, newPassword));

    notify(message, status);

    if (status) {
      setChanged(true);
    }
  };

  const onFinishFailed = () => {
    //alert("Please fill form with");
    notify("Please fill the form fields", false);
  };
  return (
    <>
      {!changed && (
        <Row gutter={[5, 5]} align="middle" justify="center">
          <Col xl={8} xs={22}>
            <Card className="card-box">
              <div className="box-pad text-center">
                <h1>Change Your Password</h1>
              </div>
              <div className="box-pad fill-width fill-height">
                <Form
                  form={form}
                  name="change-password"
                  layout="vertical"
                  initialValues={user}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  size="large"
                >
                  <Form.Item name="UserId" hidden />

                  <table style={{ width: "100%", borderCollapse: "separate" }}>
                    <tr>
                      <td>Username:</td>
                      <td className="p-3">
                        <b>{user && user.username}</b>
                      </td>
                      <td>Email:</td>
                      <td className="p-3">
                        <b>{user && user.email}</b>
                      </td>
                    </tr>
                  </table>

                  <Form.Item
                    label="Old Password"
                    name="oldPassword"
                    rules={[{ required: true, message: "Please enter your current password!" }]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item label="">
                    <Form.Item
                      label="New Password"
                      name="newPassword"
                      rules={[{ required: true, message: "Please enter your new password!" }]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      label="Confirm New Password"
                      name="ConfirmedPassword"
                      dependencies={["Password"]}
                      hasFeedback
                      rules={[
                        { required: true, message: "Please confirm your new password!" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("newPassword") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error("The confirmed password should match your new password!"));
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Change Password
                    </Button>{" "}
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      )}
      {changed && (
        <Result
          status={changed ? "success" : "warning"}
          title={
            changed ? "You have successfully changed your password" : "ٰFailed to verify your account registration"
          }
          subTitle={
            changed
              ? "You must user your new password on your next login"
              : "Please contact our support about your problem"
          }
        />
      )}
    </>
  );
};

export default ChangePassword;
