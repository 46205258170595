export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function pascalCaseToTitle(text) {
  const result = text.replace(/([A-Z])/g, " $1");

  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export function snakeCaseToTitle(text) {
  let title = text.replace(/(\$)/g, "/").split("_");
  for (let i = 0; i < title.length; i++) {
    title[i] = title[i][0].toUpperCase() + title[i].slice(1);
  }

  return title.join(" ");
}

// export async function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// }

export async function getBase64(file) {
  let reader = new FileReader();
  await reader.readAsDataURL(file);
  reader.onload = await function () {
    return reader.result;
  };
  reader.onerror = await function (error) {
    console.log("Error: ", error);
    return null;
  };
}

export function getBase64FromFile(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}
