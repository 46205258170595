import { Button } from "antd";

export const FormButtons = ({ form, handleCancel, ENTITY }) => {
  return (
    <div className="flex">
      <Button type="primary" size="large" htmlType="submit" onClick={() => form.submit()}>
        Save {ENTITY}
      </Button>
      <Button className="ml-auto" size="large" onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  );
};
